import { Observable } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material";
import { Injectable } from "@angular/core";
import { ViewProjectWikiComponent } from './view-project-wiki.component';

@Injectable({
  providedIn: 'root'
})
export class ViewProjectWikiService {
  private dialogRef: MatDialogRef<ViewProjectWikiComponent>;

  constructor(private dialog: MatDialog) { }

  public openImage(element: any): Observable<any> {
    this.dialogRef = this.dialog.open(ViewProjectWikiComponent,{});
    this.dialogRef.componentInstance.element = element;

    return this.dialogRef.afterClosed();
  }
}
