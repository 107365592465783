import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";


import { AppRoutingModule } from "./app-routing.module";
import { MaterialModule } from "./material.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { httpInterceptorProviders } from "./services/interceptors";
import { RoleGuard } from "./helpers/role.guard";
import { AuthGuard } from "./helpers/auth.guard";
import { EncrDecrService } from "./services";
import { MAT_DATE_LOCALE } from "@angular/material";
import { BuildDetailsHttpService } from '../app/cache/build-details.http.service';
import {UpToDateComponent} from './cache/up-to-date.component'

export function fetchBuildDetails(buildDetailsService: BuildDetailsHttpService) {
  return () => buildDetailsService.fetchBuildDetails();
}

@NgModule({
  declarations: [AppComponent,UpToDateComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
  ],
  providers: [
    CookieService,
    httpInterceptorProviders,
    AuthGuard,
    RoleGuard,
    EncrDecrService,
    { provide: APP_INITIALIZER, useFactory: fetchBuildDetails, deps: [BuildDetailsHttpService], multi: true },
    // { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
