import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { EmptyDriverSheetComponent } from './empty-driver-sheet.component';

@Injectable({
  providedIn: 'root'
})
export class EmptyDriverSheetService {
  private dialogRef: MatDialogRef<EmptyDriverSheetComponent>;

  constructor(private dialog: MatDialog) { }

  public confirm(title: string, driverSheet: any, buttonTitle: string,isViewProject:boolean): Observable<any> {
    this.dialogRef = this.dialog.open(EmptyDriverSheetComponent, { width: '1050px', height: '550px' });
    this.dialogRef.componentInstance.title = title;
    this.dialogRef.componentInstance.data = driverSheet;
    this.dialogRef.componentInstance.buttonTitle = buttonTitle;
    this.dialogRef.componentInstance.isViewProject = isViewProject;


    return this.dialogRef.afterClosed();
  }

  public afterClose(): string {
    this.dialogRef.afterClosed().subscribe(result => {
    });
    return this.dialogRef.componentInstance.faultId;
  }

}
