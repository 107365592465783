import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { handleApiResponse } from "src/app/config/helper.function";
import { MatDialogRef } from "@angular/material";
import { HttpErrorResponse } from "@angular/common/http";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import {
  InternalResponse,
} from "src/app/services";
import { DashboardService } from "src/app/modules/dashboard/dashboard.service";
import { ToastrService } from "ngx-toastr";
import { MessagesService } from "../messages-service/messages.service";
import { xmlInjectionValidator } from "src/app/config/custom-validator.directive";
import { PlatformService } from "src/app/modules/platform/platform.service";
import { video_image, pdf_image } from "src/app/config/const";
@Component({
  selector: "app-project-wiki-popup",
  templateUrl: "./add-project-wiki-popup.component.html",
  styleUrls: ["../shared-style.css"],
})
export class AddProjectWikiPopupComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;
  projectWikiForm: FormGroup;
  public title: string;
  public data: any;
  public buttonTitle: string;
  public fileId: string;
  isLoading: boolean = false;
  fileData: File = null;
  previewUrl: any = null;
  imageDimensionErr: string = '#c18e30';
  preSignedUrl: string;
  finalImageUrl: string;
  fileUploadProgress: string = null;
  uploadedFilePath: string;
  contentType: string;
  objectKey: string;
  fileInputElement = null;
  projectId: string = null;
  projectWikiId: string = '';
  dataContent: any;
  fileType: any = '';
  selectedFileName: any = ''
  sizeError = null

  constructor(
    public dialogRef: MatDialogRef<AddProjectWikiPopupComponent>,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public messageService: MessagesService,
    private dashboardService: DashboardService,
    public platformService: PlatformService,
  ) { }

  ngOnInit() {
    this.dataContent = this.dialogRef.componentInstance.data
    this.projectWikiId = this.dialogRef.componentInstance.fileId;
    this.projectId = this.dialogRef.componentInstance.projectId
    this.createForm();
    if (this.title === "EDIT FILE") {
      if (this.dataContent.fileUrl.includes(".com")) {
        let tempImageUrl = this.dataContent.fileUrl.split("?")[0];
        let tempObjKey = tempImageUrl.split(".com/")[1];
        let splitobject = tempObjKey.split('/').pop()
        this.objectKey = tempObjKey.replace(splitobject, this.dataContent.fileName)
        this.selectedFileName = this.dataContent.fileName;
        this.fileType = this.dataContent.fileType;
        if (this.fileType === 'image') {
          this.previewUrl = this.dataContent.fileUrl;
        } else if (this.fileType === 'video') {
          this.previewUrl = video_image;
        } else if (this.fileType === 'pdf') {
          this.previewUrl = pdf_image;
        }
      }
      this.patchFormValue();
    }
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  createForm() {
    this.projectWikiForm = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(35),xmlInjectionValidator]],
      fileName: ["", [Validators.required, xmlInjectionValidator]],
      description: ["", [Validators.maxLength(70),xmlInjectionValidator]],
      file: [null, Validators.required],
      imageUrl: []
    });
  }

  patchFormValue() {
    this.projectWikiForm = this.formBuilder.group({
      name: [this.dataContent && this.dataContent.title ? this.dataContent.title : "", [Validators.required,Validators.maxLength(35), xmlInjectionValidator]],
      fileName: [this.dataContent && this.dataContent.fileName ? this.dataContent.fileName : "", [Validators.required, xmlInjectionValidator]],
      description: [this.dataContent && this.dataContent.description ? this.dataContent.description : "", [Validators.maxLength(70),xmlInjectionValidator]],
      file: [null],
      fileUrl: [this.dataContent && this.dataContent.fileUrl ? this.dataContent.fileUrl : ""]
    });
  }


  //helper function
  fileHelper(localFile: any) {
    this.sizeError = null
    this.fileData = localFile;
    this.projectWikiForm.get('fileName').setValue(this.fileData.name)
    this.selectedFileName = this.fileData.name
    this.contentType = this.fileData.type;
    this.objectKey = `projectwiki/${this.projectId}/${this.fileData.name}`;
    this.putPresignedUrl(this.contentType, this.objectKey);
  }

  fileHelperNegative(){
    this.fileData = null
    this.projectWikiForm.get('fileName').setValue("")
    this.selectedFileName = ''
    this.contentType = '';
    this.previewUrl = ''
    this.fileType = ''

  }

  //File upload;
  async fileProgress(fileInput: any) {
    const localFileLength = fileInput.target.files.length
    const localFile = <File>fileInput.target.files[0];
    if (localFileLength !== 0) {
      if (localFile.type.match(/(image|video|pdf)\/*/) == null) {
        this.toastr.warning("Wrong file type")
        return;
      }
      if (localFile.type.includes('image')) {
        if (localFile.size < 1000000) {
          this.fileHelper(localFile)
          let img = new Image()
          img.src = window.URL.createObjectURL(<File>fileInput.target.files[0])
          img.onload = () => {
            var reader = new FileReader();
            reader.readAsDataURL(this.fileData);
            reader.onload = (_event) => {
              this.previewUrl = reader.result;
              this.fileType = 'image'
            };
          }
        } else {
          this.sizeError = { file: 'Image', isError: true, mb: 1 };
          this.fileHelperNegative()
        }

      } else if (localFile.type.includes('video')) {
        if (localFile.size < 20000000) {
          this.fileHelper(localFile)
          this.previewUrl = video_image;
          this.fileType = 'video'
        } else {
          this.sizeError = { file: 'Video', isError: true, mb: 20 };
          this.fileHelperNegative();
        }
      } else if (localFile.type.includes('pdf')) {
        if (localFile.size < 2000000) {
          this.fileHelper(localFile)
          this.previewUrl = pdf_image
          this.fileType = 'pdf'
        } else {
          this.sizeError = { file: 'PDF', isError: true, mb: 2 };
          this.fileHelperNegative();
        }
      }
    }
  }
  /**
   * Image Upload
   * @param objectKey
   */
  putPresignedUrl(contentType: string, objectKey: string) {
    this.isLoading = true;
    this.platformService.putPresignedUrl(contentType, objectKey).subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        this.preSignedUrl = responseData.success_data;
        this.s3UploadImage(contentType, this.fileData, this.preSignedUrl);
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }


  /**
   * Image Upload using HttpHeader
   * @param contentType ,
   */
  s3UploadImage(contentType: any, fileData: any, preSignedUrl: any) {
    this.platformService
      .s3UploadImage(contentType, fileData, preSignedUrl)
      .subscribe(
        (response) => {
          let responseData: InternalResponse = response;
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
  }


  /**
   *  Get Image
   * @param objectKey
   */
  getPresignedImageUrl(objectKey: string) {
    this.isLoading = true;

    this.platformService.getPresignedImageUrl(objectKey).subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        this.finalImageUrl = responseData.success_data;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  onSubmit(projectWiki: any, formDirective: FormGroupDirective) {
    if (!this.projectWikiForm.valid) {
      return;
    }
    const projectWikiApiData = {
      "projectId": this.projectId,
      "wikiList": {
        "description": projectWiki.description,
        "fileUrl": this.objectKey,
        "title": projectWiki.name,
        "id": this.projectWikiId,
        "fileName": projectWiki.fileName,
        "fileType": this.fileType,
      }
    }

    if (this.title === 'ADD FILE') {
      this.isLoading = true;
      this.dashboardService.addProjectWkiList(projectWikiApiData)
        .subscribe(
          (response) => {
            let responseData: InternalResponse = response;
            handleApiResponse(responseData, this.messageService, this.toastr);
            this.isLoading = false;
            this.dialogRef.close(true)
            formDirective.resetForm();
            this.projectWikiForm.reset();
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.message);
            this.isLoading = false;
          }
        );

    } else if (this.title === 'EDIT FILE') {
      this.isLoading = true;
      this.dashboardService
        .updateProjectWikiList(projectWikiApiData)
        .subscribe(
          (response) => {
            let responseData: InternalResponse = response;
            handleApiResponse(responseData, this.messageService, this.toastr);
            this.isLoading = false;
            this.dialogRef.close(true)
            formDirective.resetForm();
            this.projectWikiForm.reset();
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.message);
            this.isLoading = false;
          }
        );
    }
  }

  getErrorName() {
    return this.projectWikiForm.get("name").hasError("required")
      ? "Name is required."
      :this.projectWikiForm.get("name").hasError("maxlength")
      ? "Maximum length of name is 35 characters"
      : this.projectWikiForm.get("name").hasError("sqlInjection")
        ? "SQL/XML/Script pattern not allowed. Please check."
        : this.projectWikiForm.get("name").hasError("xmlInjection")
          ? "SQL/XML/Script pattern not allowed. Please check."
          : "";
  }

  getErrorDescription() {
    return this.projectWikiForm.get("description").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.projectWikiForm.get("description").hasError("maxlength")
      ? "Maximum length of description is 70 characters"
      : this.projectWikiForm.get("description").hasError("xmlInjection")
        ? "SQL/XML/Script pattern not allowed. Please check."
        : "";
  }
  getErrorFile() {
    return this.projectWikiForm.get("file").hasError("required")
      ? "File is required."
      : this.projectWikiForm.get("file").hasError("sqlInjection")
        ? "SQL/XML/Script pattern not allowed. Please check."
        : this.projectWikiForm.get("file").hasError("xmlInjection")
          ? "SQL/XML/Script pattern not allowed. Please check."
          : "";
  }
}
