import { Component, OnInit } from "@angular/core";
import { handleApiResponse } from "src/app/config/helper.function";
import { MatDialogRef } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  InternalResponse,
  INTERNAL_RESPONSE_STATUS,
  Fault,
  DriverSheetSubmitted,
} from "src/app/services";
import { DashboardService } from "src/app/modules/dashboard/dashboard.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/modules/auth/auth.service";
import { ico_excavator } from "src/app/config/const";
import { DriverSheetDetailsService } from "../driver-sheet-details/driver-sheet-details.service";
import { CommonsService } from "src/app/modules/common/commons.service";
import { PlatformService } from "src/app/modules/platform";
import { ExpandImageService } from "../expand-image/expand-image.service";
import { switchMap } from "rxjs/operators";
import { MessagesService } from "../messages-service/messages.service";
import { Subscription } from "rxjs";
import * as moment from "moment";

@Component({
  selector: "app-fault-details",
  templateUrl: "./fault-details.component.html",
  styleUrls: ["../shared-style.css"],
})
export class FaultDetailsComponent implements OnInit {
  registerForm: FormGroup;
  public title: string;
  public data: any;
  public buttonTitle: string;
  public faultId: string;
  public ico_excavator: string;
  public imageUrls: string[] = [];
  public videoUrls: string[] = [];
  loginInUserRole: string;
  private userSub: Subscription;
  screenWidth: any;
  permission: string[];
  moment:any = moment
  constructor(
    public dialogRef: MatDialogRef<FaultDetailsComponent>,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private driverSheetDetailsService: DriverSheetDetailsService,
    private commonsService: CommonsService,
    private platformService: PlatformService,
    public expandImageService: ExpandImageService,
    public messageService: MessagesService
  ) {}

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.ico_excavator = ico_excavator;

    this.userSub = this.authService.user.subscribe((user) => {
      if (user) {
        this.loginInUserRole = user.roleId;
        this.permission = user.appPermissions
      }
    });
    if (this.dialogRef.componentInstance.data.evidenceUrl !== null) {
      let objectKeys: string[] = this.dialogRef.componentInstance.data.evidenceUrl.split(
        ","
      );
      for (var i = 0; i < objectKeys.length; i++) {
        this.getPresignedImageUrl(objectKeys[i]);
      }
    }

    this.createForm();
  }
  formGroup: FormGroup;

  createForm() {
    const isRepairActionRequired = (this.dialogRef.componentInstance.data.status == 'Closed') ? [Validators.required] : null;
    this.formGroup = this.formBuilder.group({
      evidences: [this.dialogRef.componentInstance.data.evidenceUrl],
      safe: [this.dialogRef.componentInstance.data.safe.toString()],
      nmc: [this.dialogRef.componentInstance.data.nMC.toString()],
      status: [this.dialogRef.componentInstance.data.status],
      repairAction: [this.dialogRef.componentInstance.data.repairAction, isRepairActionRequired],
      lostHrs: [this.dialogRef.componentInstance.data.lostHrs],
      owner:[this.dialogRef.componentInstance.data.owner]
    });
    if(this.dialogRef.componentInstance.data.status == 'Closed'){
      this.formGroup.get("repairAction").markAsTouched({ onlySelf: true });
    }
  }



  updateFault(data, action: string) {
    let evidence: string[];
    if (data.evidences !== null) {
      evidence = data.evidences.split(",");
    }
    if (action === "RESOLVED") {
      data.status = "Closed";
    }

    let faultData: Fault = {
      status: data.status,
      safe: data.safe,
      nMC: data.nmc,
      repairAction: data.repairAction,
      faultId: this.dialogRef.componentInstance.data.faultId,
      driverSheetSubmittedId: this.dialogRef.componentInstance.data
        .driverSheetSubmittedId,
      fault: this.dialogRef.componentInstance.data.fault,
      description: this.dialogRef.componentInstance.data.description,
      failureHr: this.dialogRef.componentInstance.data.failureHr,
      hrsDiffPreviousFault: this.dialogRef.componentInstance.data
        .hrsDiffPreviousFault,
      lostHrs: data.lostHrs,
      action: this.dialogRef.componentInstance.data.action,
      openDate: this.dialogRef.componentInstance.data.openDate,
      closeDate: this.dialogRef.componentInstance.data.closeDate,
      owner: data.owner,
      evidenceUrl: evidence,
      failureType: this.dialogRef.componentInstance.data.failureType,
      component: this.dialogRef.componentInstance.data.component,
      dutyCycle: this.dialogRef.componentInstance.data.dutyCycle,
      problemAggregate: this.dialogRef.componentInstance.data.problemAggregate,
      vin: this.dialogRef.componentInstance.data.vin,
      
    };

    this.dashboardService.addNewFault(faultData).subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        handleApiResponse(responseData, this.messageService, this.toastr);
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  showDriverSheetDetails(driverSheetSubmittedId: string) {
    this.commonsService.getDS_Submitted(driverSheetSubmittedId).subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          this.showDriverSheetForm(responseData.success_data);
        } else {
          response.code != 401 &&
            this.messageService.openDialog("ERROR", response.error_message);
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  showDriverSheetForm(driverSheet: DriverSheetSubmitted) {
    let title = "View Driver Sheet";
    this.driverSheetDetailsService
      .confirm(title, driverSheet, "MARK AS REVIEWED",false)
      .subscribe((res) => {
        if (res === "") {
        }
      });
  }

  /**
   *  Get Image
   * @param objectKey
   */
  getPresignedImageUrl(objectKey: string) {
    this.platformService.getPresignedImageUrl(objectKey).subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (objectKey.includes(".mp4") || objectKey.includes(".MOV")) {
          this.videoUrls.push(responseData.success_data);
        } else {
          this.imageUrls.push(responseData.success_data);
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  fullScreenImage(imageUrl: string) {
    this.expandImageService
      .openImage(imageUrl)
      .pipe(
        switchMap((res) => {
          if (res === true) {
            return null;
          }
        })
      )
      .subscribe(
        (response) => {},
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
  }

  changeStatus(event: any){
    if(event == 'Closed'){
      this.formGroup.controls["repairAction"].setValidators([Validators.required]);
      this.formGroup.controls["repairAction"].updateValueAndValidity();
      this.formGroup.get("repairAction").markAsTouched({ onlySelf: true });
    } else {
      this.formGroup.controls["repairAction"].setValidators(null);
      this.formGroup.controls["repairAction"].updateValueAndValidity();
    }
  }
}
