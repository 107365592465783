import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { DriverSheetDetailsComponent } from './driver-sheet-details.component';

@Injectable({
  providedIn: 'root'
})
export class DriverSheetDetailsService {
  private dialogRef: MatDialogRef<DriverSheetDetailsComponent>;

  constructor(private dialog: MatDialog) { }

  public confirm(title: string, driverSheet: any, buttonTitle: string, draft:boolean): Observable<any> {
    this.dialogRef = this.dialog.open(DriverSheetDetailsComponent, { width: '1050px', height: '550px' });
    this.dialogRef.componentInstance.title = title;
    this.dialogRef.componentInstance.data = driverSheet;
    this.dialogRef.componentInstance.buttonTitle = buttonTitle;
    this.dialogRef.componentInstance.draft =draft

    return this.dialogRef.afterClosed();
  }

  public afterClose(): string {
    this.dialogRef.afterClosed().subscribe(result => {
    });
    return this.dialogRef.componentInstance.faultId;
  }

}
