import { Component, OnInit, Inject } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MessagesService } from "src/app/shared/messages-service/messages.service";
import { MachineService } from "../machine.service";
import { INTERNAL_RESPONSE_STATUS, InternalResponse } from "src/app/services";
import { HttpErrorResponse } from "@angular/common/http";
import { S3Service } from "src/app/helpers/aws.s3.service";

@Component({
  selector: "app-view-qr-code",
  templateUrl: "./view-qr-code.component.html",
  styleUrls: ["../../style.css"],
})
export class ViewQRCodeComponent implements OnInit {
  isLoading: boolean = false;
  title: string;
  buttonName: string;
  vin:string;
  qrCode:string;
  isLoadingDowmload:any = false

  constructor(
    private toastr: ToastrService,
    public messageService: MessagesService,
    public dialogRef: MatDialogRef<ViewQRCodeComponent>,
    private machineService: MachineService,
    private s3Service: S3Service,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.buttonName = this.data.buttonName;
    this.vin = this.data.vin
    this.getMachineDetails(this.data.vin);
  }

   /**
   * Gets Machine details
   * @param selectedMachineId
   */
   getMachineDetails(vin: string) {
    this.isLoadingDowmload = true;
    this.machineService.getMachineDetails(vin).subscribe(
      (response) => {
        this.isLoadingDowmload =false
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          this.qrCode = responseData.success_data.qrCode;
        } else {
          this.isLoadingDowmload =false
          response.code != 401 &&
            this.toastr.error(response.error_message, "ERROR");
        }
      },
      (err: HttpErrorResponse) => {
        this.isLoadingDowmload =false
        console.log(err.error);
        console.log(err.message);
      }
    );
  }



  downloadQrCode(){
    this.isLoadingDowmload = true
    let tempObjKey = ''
    if (this.qrCode.includes(".com")) {
      let tempImageUrl = this.qrCode.split("?")[0];
       tempObjKey = tempImageUrl.split(".com/")[1];
       let splitobject = tempObjKey.split('/').pop()
      const bucketName = 'serviceprojects-storage';
      const key = tempObjKey;
      this.s3Service.downloadFileFromS3(bucketName, key).subscribe(response => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      let currentTime = new Date().getTime();
      a.download = `${currentTime}_${splitobject}`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.isLoadingDowmload = false;
      this.dialogRef.close(true)
    });
  }
    
  }
}


