import { Component, OnInit } from "@angular/core";
import { handleApiResponse } from "src/app/config/helper.function";
import { MatDialogRef } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import * as moment from "moment";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import {
  InternalResponse,
  INTERNAL_RESPONSE_STATUS,
  CommonList,
} from "src/app/services";
import {
  UPDATE_COMPONENTS,
} from "src/app/config/backend.api.urls";
import { DashboardService } from "src/app/modules/dashboard/dashboard.service";
import { ToastrService } from "ngx-toastr";
import { RegisterOperatorService } from "src/app/modules/register-operator/register-operator.service";
import { AuthService } from "src/app/modules/auth/auth.service";
import { MessagesService } from "../messages-service/messages.service";
import { xmlInjectionValidator} from "src/app/config/custom-validator.directive";
import {decryptDes} from "src/app/helpers/crypto";
import { ConfirmService } from "../confirm-dialog/confirm.service";

@Component({
  selector: "app-edit-component-popup",
  templateUrl: "./edit-component-popup.component.html",
  styleUrls: ["../shared-style.css"],
})
export class EditComponentPopupComponent implements OnInit {
  componentForm: FormGroup;
  public title: string;
  public data: any;
  public buttonTitle: string;
  public componentId: string;
  addOperator: boolean = false;
  updateOperator: boolean = false;
  hide = true;
  hideEmail: boolean = false;
  hideDevice: boolean = false;
  hideRole: boolean = false;
  hidePassword: boolean = false;
  isLoading: boolean = false;
  failedData:any = ['Failed','Running']
  roleList: CommonList[];
  devices: CommonList[];
  countries: CommonList[];
  retrievedPassword:string;
  dataContent:any
  failed:boolean = true
  maxDate:string = "";
  minDate:string = "";
  endHmr:any;
  showFields:boolean = false;
  constructor(
    public dialogRef: MatDialogRef<EditComponentPopupComponent>,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private confirmService: ConfirmService,
    private registerOperatorService: RegisterOperatorService,
    public messageService: MessagesService,
    private dashboardService: DashboardService,
  ) {}

  ngOnInit() {
    this.dataContent = this.dialogRef.componentInstance.data
   this.componentId = this.dialogRef.componentInstance.componentId;
    if (this.dialogRef.componentInstance.data !== null) {
      this.failed = this.dialogRef.componentInstance.data.failed
      this.maxDate = moment(new Date()).format("YYYY-MM-DD")
      this.minDate = this.dialogRef.componentInstance.data.startDate
      this.endHmr = this.dialogRef.componentInstance.data.endHmr;
        this.updateForm(this.dialogRef.componentInstance.data);
    }
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onStatusSelect(data){
    if(data === 'Failed'){
      this.showFields= true
      this.componentForm.get('dateOfFailure').setValidators([Validators.required])
      this.componentForm.get('dateOfFailure').updateValueAndValidity()
      this.componentForm.get('failedHmr').setValidators([Validators.required,Validators.min(this.dialogRef.componentInstance.data.fittedHmr),Validators.max(this.endHmr)])
      this.componentForm.get('failedHmr').updateValueAndValidity()

    }else if(data === 'Running'){
      this.showFields= false
      this.componentForm.get('dateOfFailure').clearValidators()
      this.componentForm.get('dateOfFailure').updateValueAndValidity()
      this.componentForm.get('failedHmr').clearValidators()
      this.componentForm.get('failedHmr').updateValueAndValidity()

    }
  }

  updateForm(data) {
    this.showFields = data.failed
    this.componentForm = this.formBuilder.group({
      failed: [data && data.failed ? "Failed" : "Running"],
      dateOfFailure: [data && data.failedDate ? data.failedDate :"",[]],
      failedHmr: [data && data.failedHmr ? data.failedHmr :"", []],
      failureDescription: [data && data.statusDescription ? data.statusDescription :"",[xmlInjectionValidator]]
    });
  }

  onSubmit(
    componentDetails: any,
    formDirective: FormGroupDirective
  ) {
    if(componentDetails.failed === 'Failed'){
       this.confirmPopup(
        componentDetails,
        formDirective
       )
    }else{
      this.submitForm(componentDetails,
        formDirective)
    }
    
    }

    submitForm( componentDetails: any,
      formDirective: FormGroupDirective){
      if (!this.componentForm.valid) {
        return;
      }
          this.isLoading = true;
          this.dashboardService
            .updateProjectComponentList(this.componentId,componentDetails.failureDescription,componentDetails.failed === 'Failed' ? true : false,moment(componentDetails.dateOfFailure).format("YYYY-MM-DD"),componentDetails.failedHmr)
            .subscribe(
              (response) => {
                let responseData: InternalResponse = response;
                  handleApiResponse(responseData, this.messageService, this.toastr);
                this.isLoading = false;
                this.dialogRef.close(true)
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.message);
                this.isLoading = false;
              }
            );
        formDirective.resetForm();
        this.componentForm.reset();
    }


    confirmPopup(componentDetails: any,
      formDirective: FormGroupDirective) {
      this.confirmService
      .confirm(
        "Update Component",
        "Are you sure, You want to update this Component?",
        "Please note that, You can't revert this action",
        "YES"
      )
      .subscribe(
        (response) => {
           if(response === true){
            this.submitForm(componentDetails,
              formDirective)
           }
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
          this.messageService.openDialog("Error", "Remove did not happen.");
        }
      );
   }

    
  


  getErrorDateOfFailure() {
  return this.componentForm.get("dateOfFailure").hasError("required")
    ? "Date of failure is required."
    :this.componentForm.get("dateOfFailure").hasError("sqlInjection")
    ? "SQL/XML/Script pattern not allowed. Please check."
    :this.componentForm.get("dateOfFailure").hasError("xmlInjection")
    ? "SQL/XML/Script pattern not allowed. Please check."
    : "";
  }
  
  getErrorFailedHmr() {
    return this.componentForm.get("failedHmr").hasError("required")
      ? "Failed HMR is required." 
      :this.componentForm.get("failedHmr").hasError("min")
      ? "The Failed HMR should not less then Fitted HMR."
      :this.componentForm.get("failedHmr").hasError("max")
      ? "The Failed HMR should not more then Current HMR."
      :this.componentForm.get("FailedHmr").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.componentForm.get("FailedHmr").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }
  getErrorDesc() {
    return this.componentForm.get("failureDescription").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.componentForm.get("failureDescription").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }
}
