import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-view-fault-details',
  templateUrl: './view-fault-details.component.html',
  styleUrls: ["../../shared-style.css"]
})
export class ViewFaultDetailsComponent {

  constructor(
    public dialogRef: MatDialogRef<ViewFaultDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public onDismiss(): void {
    this.dialogRef.close(false);
  }

}
