import { Component, Inject,OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ExpandImageService } from 'src/app/shared/expand-image/expand-image.service';
import { switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-view-comments',
  templateUrl: './view-comments.component.html',
  styleUrls: ["../../shared-style.css"]
})
export class ViewCommentsComponent implements OnInit {
   mediaData = []
  constructor(
    public dialogRef: MatDialogRef<ViewCommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public expandImageService: ExpandImageService,
  ) { }

  ngOnInit(): void {
    this.mediaData  = this.data.media.split(",").map((data)=>{
      return 'https://serviceprojects-storage.s3.ap-southeast-1.amazonaws.com/'+data
    })
    console.log("this.data?.media",this.mediaData)
  }

  public onDismiss(): void {
    this.dialogRef.close(false);
  }

  fullScreenImage(imageUrl: string) {
    this.expandImageService
      .openImage(imageUrl)
      .pipe(
        switchMap(res => {
          if (res === true) {
            return null;
          }
        })
      )
      .subscribe(
        response => {
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        });
  }

}
