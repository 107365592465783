import { Injectable } from '@angular/core';
import { FaultDetailsComponent } from './fault-details.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaultDetailsService {
  private dialogRef: MatDialogRef<FaultDetailsComponent>;

  constructor(private dialog: MatDialog) { }

  public confirm(title: string, fault: any, buttonTitle: string): Observable<any> {
    this.dialogRef = this.dialog.open(FaultDetailsComponent, { height: '550px' });
    this.dialogRef.componentInstance.title = title;
    this.dialogRef.componentInstance.data = fault;
    this.dialogRef.componentInstance.buttonTitle = buttonTitle;

    return this.dialogRef.afterClosed();
  }

  public afterClose(): string {
    this.dialogRef.afterClosed().subscribe(result => {

    });
    return this.dialogRef.componentInstance.faultId;
  }
}
