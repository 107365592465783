import { Observable } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material";
import { Injectable } from "@angular/core";

import { SelectProjectComponent } from "./select-project.component";
import { CommonList } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class SelectProjectService {
  private dialogRef: MatDialogRef<SelectProjectComponent>;
   public modelList: Array<any>;
  constructor(private dialog: MatDialog) {

   }

  public confirm(title: string, projectList: CommonList[]): Observable<any> {
    this.dialogRef = this.dialog.open(SelectProjectComponent, {});
    this.dialogRef.componentInstance.title = title;
    this.dialogRef.componentInstance.data = projectList;

    return this.dialogRef.afterClosed();
  }

  public afterClose(): string {
    this.dialogRef.afterClosed().subscribe(result => {
    });
    return this.dialogRef.componentInstance.projectId;
  }

}
