import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-view-project-wiki',
  templateUrl: './view-project-wiki.component.html',
  styleUrls: ["../shared-style.css"]
})
export class ViewProjectWikiComponent implements OnInit {
  public element: any;
  fileType:any;
  constructor(public dialogRef: MatDialogRef<ViewProjectWikiComponent>) { }

  ngOnInit() {
    this.fileType = this.element.fileType;
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
