import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { edit_image, remove_image } from "src/app/config/const";

import { DashboardService } from "./../../dashboard/dashboard.service";
import {
  InternalResponse,
  INTERNAL_RESPONSE_STATUS,
  CommonList,
  RegisterOperatorRequest,
} from "src/app/services";
import { AuthService } from "../../auth/auth.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { MatTableDataSource, MatDialog } from "@angular/material";
import { ConfirmService, MessagesService } from "src/app/shared";
import { switchMap } from "rxjs/operators";
import { PlatformService } from "../platform.service";
import { EditPlatformComponent } from "./edit-platform/edit-platform.component";
import { PlantService } from "../../plant";
import { EditPlantComponent } from "./edit-plant/edit-plant.component";
import { EditModelComponent } from "./edit-model/edit-model.component";
import { Subscription } from "rxjs";
import Sort from "src/app/helpers/sort";

@Component({
  selector: "app-plant-platform-details",
  templateUrl: "./plant-platform-details.component.html",
  styleUrls: ["../../style.css"],
})
export class PlantPlatformDetailsComponent implements OnInit {
  registerForm: FormGroup;
  isLoading: boolean = false;
  edit_image: string;
  remove_image: string;
  public plantSource = new MatTableDataSource<any>();
  public platformSource = new MatTableDataSource<any>();
  public modelSource = new MatTableDataSource<any>();
  public userList: CommonList[];
  public operatorRequest: RegisterOperatorRequest;
  public finalImageUrl: string;
  loginInUserRole: string;
  private userSub: Subscription;
  public modelList = [];
  permission: string[];

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private router: Router,
    private confirmService: ConfirmService,
    private messagesService: MessagesService,
    private platformService: PlatformService,
    private plantService: PlantService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.edit_image = edit_image;
    this.remove_image = remove_image;
    this.userSub = this.authService.user.subscribe((user) => {
      if (user) {
        this.loginInUserRole = user.roleId;
        this.permission = user.appPermissions;
      }
    });
    if (this.loginInUserRole === "R02") {
      this.dashboardService.setTitle("Platforms & Models");
    } else {
      this.dashboardService.setTitle("Plants, Platforms & Models");
    }
    this.getPlatformDetailsList();
    this.getPlantDetailsList();
    this.getModelDetailsList();
  }

  backToDashboard() {
    this.router.navigate(["./dashboard"]);
  }
  plantsColumn = [
    "plantName",
    "city",
    "address",
    "country",
    // "plantPOCEmail",
    "active",
    "actions",
  ];
  plantsColumns = [
    "plantName",
    "city",
    "address",
    "country",
    // "plantPOCEmail",
    "active",
  ];
  platformsColumn = [
    "imageUrl",
    "platformName",
    "platformShortName",
    "description",
    "active",
    "actions",
  ];
  platformsColumns = [
    "imageUrl",
    "platformName",
    "platformShortName",
    "description",
    "active",
  ];
  modelsColumn = [
    "modelName",
    "description",
    "platformName",
    "active",
    "actions",
  ];
  modelsColumns = [
    "modelName",
    "description",
    "platformName",
    "active",
  ];

  getPlatformDetailsList() {
    let platformSource;
    this.isLoading = true;
    this.authService.getPlatformDetailsList().subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          console.log(response.success_data.platforms);
          platformSource = Sort(response.success_data.platforms, "name");
          this.platformSource = new MatTableDataSource(platformSource);
          this.isLoading = false;
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
        this.isLoading = false;
      }
    );
  }

  applyFilter(filterValue: string) {
    this.platformSource.filter = filterValue.trim().toLowerCase();
    if (this.platformSource.paginator) {
      this.platformSource.paginator.firstPage();
    }
  }

  onEditPlatform(platformData: any) {
    const dialogRef = this.dialog.open(EditPlatformComponent, {
      data: {
        platformData: platformData,
        action: "EDIT",
        buttonName: "SAVE PLATFORM",
        title: "EDIT PLATFORM",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.getPlatformDetailsList();
      }
    });
  }

  onAddPlatform() {
    const dialogRef = this.dialog.open(EditPlatformComponent, {
      data: {
        platformData: null,
        action: "ADD",
        buttonName: "ADD PLATFORM",
        title: "ADD NEW PLATFORM",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.getPlatformDetailsList();
      }
    });
  }

  onDeactivatePlatform(platformId: string) {
    this.confirmService
      .confirm(
        "Deactivate Platform",
        "Are you Sure to DE-ACTIVATE the Selected Platform?",
        "Note that by deactivating this, Platform will not be visible accross the application, and the data, files and information associated with it will become un-available.",
        "DEACTIVATE NOW"
      )
      .pipe(
        switchMap((res) => {
          if (res === true) {
            return this.platformService.deletePlatform(platformId);
          }
        })
      )
      .subscribe(
        (response) => {
          this.success();
          this.onSubmitPlatform();
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
          this.messagesService.openDialog(
            "Error",
            "Deactivation did not happen."
          );
        }
      );
  }

  private success() {
    this.messagesService.openDialog(
      "Success",
      "Platform Deactivated as you wished!"
    );
  }

  onSubmitPlatform() {
    this.getPlatformDetailsList();
  }

  getPlantDetailsList() {
    let plantSource;
    this.isLoading = true;
    this.authService.getPlantDetailsList().subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          plantSource = Sort(response.success_data.plants, "plantName");

          this.plantSource = new MatTableDataSource(plantSource);
          this.isLoading = false;
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
        this.isLoading = false;
      }
    );
  }

  applyPlantFilter(filterValue: string) {
    this.plantSource.filter = filterValue.trim().toLowerCase();
    if (this.plantSource.paginator) {
      this.plantSource.paginator.firstPage();
    }
  }

  onEditPlant(plantData: any) {
    const dialogRef = this.dialog.open(EditPlantComponent, {
      data: {
        plantData: plantData,
        action: "EDIT",
        buttonName: "SAVE PLANT",
        title: "EDIT PLANT",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.getPlantDetailsList();
      }
    });
  }

  onAddPlant() {
    const dialogRef = this.dialog.open(EditPlantComponent, {
      data: {
        platformData: null,
        action: "ADD",
        buttonName: "ADD PLANT",
        title: "ADD NEW PLANT",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.getPlantDetailsList();
      }
    });
  }

  onDeactivatePlant(plantId: string) {
    this.confirmService
      .confirm(
        "Deactivate Plant",
        "Are you Sure to DE-ACTIVATE the Selected Plant?",
        "Note that by deactivating this, Plant will not be visible accross the application, and the data, files and information associated with it will become un-available.",
        "DEACTIVATE NOW"
      )
      .pipe(
        switchMap((res) => {
          if (res === true) {
            return this.plantService.deletePlant(plantId);
          }
        })
      )
      .subscribe(
        (response) => {
          this.successPlant();
          this.onSubmitPlant();
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
          this.messagesService.openDialog(
            "Error",
            "Deactivation did not happen."
          );
        }
      );
  }

  private successPlant() {
    this.messagesService.openDialog(
      "Success",
      "Plant Deactivated as you wished!"
    );
  }

  onSubmitPlant() {
    this.getPlantDetailsList();
  }

  getActiveStyleStatus(status: boolean) {
    if (status) {
      return "#4C894E";
    } else {
      return "#A32424";
    }
  }

  getActiveText(status: boolean): string {
    if (status) {
      return "Active";
    } else {
      return "In-Active";
    }
  }

  getModelDetailsList() {
    let modelSource;
    this.isLoading = true;
    this.authService.getModelDetailsList().subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          modelSource = Sort(response.success_data.models, "modelName");
          this.modelList = modelSource;
          this.modelSource = new MatTableDataSource(modelSource);
          this.isLoading = false;
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
        this.isLoading = false;
      }
    );
  }

  applyModelFilter(filterValue: string) {
    this.modelSource.filter = filterValue.trim().toLowerCase();
    if (this.modelSource.paginator) {
      this.modelSource.paginator.firstPage();
    }
  }

  onEditModel(modelData: any) {
    const dialogRef = this.dialog.open(EditModelComponent, {
      data: {
        modelData: modelData,
        action: "EDIT",
        buttonName: "SAVE MODEL",
        title: "EDIT MODEL",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.getModelDetailsList();
      }
    });
  }

  onAddModel() {
    const dialogRef = this.dialog.open(EditModelComponent, {
      data: {
        modelData: this.modelList,
        action: "ADD",
        buttonName: "ADD MODEL",
        title: "ADD NEW MODEL",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.getModelDetailsList();
      }
    });
  }

  onDeactivateModel(modelId: string) {
    this.confirmService
      .confirm(
        "Deactivate Model",
        "Are you Sure to DE-ACTIVATE the Selected Model?",
        "Note that by deactivating this, Model will not be visible accross the application, and the data, files and information associated with it will become un-available.",
        "DEACTIVATE NOW"
      )
      .pipe(
        switchMap((res) => {
          if (res === true) {
            return this.platformService.deleteModel(modelId);
          }
        })
      )
      .subscribe(
        (response) => {
          this.successModel();
          this.onSubmitModel();
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
          this.messagesService.openDialog(
            "Error",
            "Deactivation did not happen."
          );
        }
      );
  }

  private successModel() {
    this.messagesService.openDialog(
      "Success",
      "Model Deactivated as you wished!"
    );
  }

  onSubmitModel() {
    this.getModelDetailsList();
  }
}
