import { Subscription } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { AuthService } from "../modules/auth/auth.service";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class RoleGuard implements CanActivate, OnDestroy {
  private currentUser: Subscription;
  userData: any;
  permission: string[];
  constructor(
    private _authGuard: AuthGuard,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean>  {
    return this._authGuard.canActivate(route, state).then((auth: boolean) => {
      this.currentUser = this.authService.user.subscribe(userData => {
        this.userData = userData;
        if (this.userData) {
          this.permission = this.userData.appPermissions;
        }
      });

      if(!auth) {
        return Promise.resolve(false)
      }

      if (this.permission.includes(route.data[0])) {
        return Promise.resolve(true)
      } else {
        this.toastr.error("Not authorized to access this page.", "ERROR");
        this.router.navigate(["/dashboard"]);
        return Promise.resolve(true)
      }

    });
  }

  ngOnDestroy(): void {
    this.currentUser.unsubscribe();
  }
}
