import { Subscription } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { AuthService } from "../modules/auth/auth.service";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate, OnDestroy {
  private currentUser: Subscription;
  userData: any;
  roleId: string;
  accessToken: string;
  constructor(private router: Router, private authService: AuthService, private toastr: ToastrService,) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    this.currentUser = this.authService.user.subscribe(userData => {
      this.userData = userData;
      if (this.userData) {
        this.roleId = this.userData.roleId;
        this.accessToken = this.userData.accessToken;
      }
    });
    return new Promise((resolve: Function, reject: Function) => {
      if (this.roleId === 'R02' || this.roleId === 'R03') {
        // authorised so return true
        resolve(true);
      }else if(state.url.includes("/archive-project") || state.url.includes("/view-project") && this.roleId === 'R00'){
        resolve(true);
      } else {
        if(this.accessToken){
          this.toastr.error("Not authorized to access this page.", "ERROR");
          this.router.navigate(["/dashboard"]);
        }else{
         // not logged in so redirect to login page with the return url
          this.router.navigate(["/auth"]);
        }
        reject(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.currentUser.unsubscribe();
  }
}
