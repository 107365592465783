import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { InternalResponse, Comments } from "src/app/services";
import { ToastrService } from "ngx-toastr";
import { DashboardService } from "src/app/modules/dashboard/dashboard.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { HttpErrorResponse } from "@angular/common/http";
import { handleApiResponse } from "src/app/config/helper.function";
import { MessagesService } from "../../messages-service/messages.service";

@Component({
  selector: "app-edit-comments",
  templateUrl: "./edit-comments.component.html",
  styleUrls: ["../../shared-style.css"],
})
export class EditCommentsComponent {
  isLoading: boolean = false;
  commentForm: FormGroup;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    public messageService: MessagesService,
    public dialogRef: MatDialogRef<EditCommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.commentForm = this.formBuilder.group({
      title: [this.data.title, Validators.required],
      description: [this.data.description, Validators.required],
    });
  }

  /**
   * Edit Faults
   * @param updatedFaultData
   */
  updateComment(updatedUserData: Comments, formDirective: FormGroupDirective) {
    this.isLoading = true;

    updatedUserData.driverSheetSubmittedCommentsId = this.data.driverSheetSubmittedCommentsId;
    updatedUserData.driverSheetSubmittedId = this.data.driverSheetSubmittedId;
    updatedUserData.userId = this.data.userId;
    updatedUserData.addedBy = this.data.addedBy;
    updatedUserData.date = this.data.date;
    updatedUserData.vin = this.data.vin;
    updatedUserData.media = this.data.media;
    this.dashboardService.updateComment(updatedUserData).subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        handleApiResponse(responseData, this.messageService, this.toastr);
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
    this.commentForm.reset();
    formDirective.resetForm();
  }

  getTitleError() {
    return this.commentForm.get("title").hasError("required")
      ? "Status is required"
      : "";
  }

  getDescriptionError() {
    return this.commentForm.get("description").hasError("required")
      ? "Description is required"
      : "";
  }

  getMediaError() {
    return this.commentForm.get("media").hasError("required")
      ? "Problem Aggregate is required"
      : "";
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
