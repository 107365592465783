import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import {
  createUrl,
  getHeader,
  handleError,
  modifyResponse,
  RegisterOperatorRequest
} from "src/app/services";
import { REGISTER_OPERATOR, UPDATE_USER } from "src/app/config/backend.api.urls";

@Injectable({
  providedIn: "root"
})
export class RegisterOperatorService {
  dialogData: any;
  constructor(private http: HttpClient) {}

  registerOperator(reqData: RegisterOperatorRequest) {
    return this.http
      .post(createUrl(REGISTER_OPERATOR), reqData, {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, REGISTER_OPERATOR);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  updateUserDetail(reqData, apiCallUrl) {
    const params = this.getUserUpdateParams(reqData);
    return this.http
      .put(createUrl(apiCallUrl), reqData, {
        headers: getHeader(),
        params: params
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, apiCallUrl);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  getUserUpdateParams(reqData) {
    const params = new HttpParams()
      .set("firstName", reqData.firstName)
      .set("lastName", reqData.lastName)
      .set("active", reqData.status)
      .set("address", reqData.location)
      .set("country", reqData.country)
      .set("phoneNumber", reqData.phoneNumber)
      .set("roleId", reqData.roleId)
      .set("userId", reqData.userId)
      .set("image", reqData.image)
      .set("smsLanguage", reqData.smsLanguage)
      .set("userEmail", reqData.email)
      .set("deviceId", reqData.deviceId)
      .set("deviceDesc", reqData.deviceDesc)
      .set("isBlocked", reqData.isBlocked)
      .set("password", reqData.password);
    return params;
  }

  deactivateUser(userId: string) {
    this.dialogData = userId;
    const params = new HttpParams().set("userId", userId);
    return this.http
      .delete(createUrl(UPDATE_USER), {
        headers: getHeader(),
        params: params
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, UPDATE_USER);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }
}
