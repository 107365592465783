export class User {
  constructor(
    public accessToken: string,
    public bucketAccesskey: string,
    public bucketSecretkey: string,
    public appPermissions: string[],
    public twoFactorAuthenticationReq: boolean,
    public country: string,
    public email: string,
    public firstName: string,
    public image: string,
    public lastName: string,
    public roleId: string,
    public userId: string,
    public projectList: string[],
  ) {}

  get token() {
    //Check is token is exits or not
    return this.accessToken;
  }
}
