import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import {
  InternalResponse,
  INTERNAL_RESPONSE_STATUS,
  Platform,
} from "src/app/services";
import { handleApiResponse } from "src/app/config/helper.function";
import { HttpErrorResponse } from "@angular/common/http";
import { PlatformService } from "../../platform.service";
import { MessagesService } from "src/app/shared/messages-service/messages.service";
import { sqlInjectionValidator ,xmlInjectionValidator} from "src/app/config/custom-validator.directive";

@Component({
  selector: "app-edit-platform",
  templateUrl: "./edit-platform.component.html",
  styleUrls: ["../../../style.css"],
})
export class EditPlatformComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;
  isLoading: boolean = false;
  title: string;
  platformForm: FormGroup;
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  finalImageUrl: string;
  buttonName: string;
  uploadedFilePath: string;
  contentType: string;
  objectKey: string;
  preSignedUrl: string;
  imageDimensionErr: string = '#c18e30';
  public platformList: any;
  public platformNameList: any;
  fileInputElement = null

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditPlatformComponent>,
    public platformService: PlatformService,
    public messageService: MessagesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getPlatformList();
    this.title = this.data.title;
    this.buttonName = this.data.buttonName;
    this.createForm();
    if (this.data.action === "EDIT") {
      if (this.data.platformData.imageUrl.includes(".com")) {
        let tempImageUrl = this.data.platformData.imageUrl.split("?")[0];
        let tempObjKey = tempImageUrl.split(".com/")[1];
        this.objectKey = tempObjKey;
      }
      this.patchFormValue();
    }
  }

  getPlatformList() {
    this.platformService.getPlatformList().subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          this.platformList = response.success_data.platforms;
          this.platformList = this.platformList.filter(
            (pl) => pl.active === true
          );
          this.platformNameList = this.platformList.map(
            (platform: any) => platform.name
          );
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  createForm() {
    this.platformForm = this.formBuilder.group({
      platformId: [null],
      imageUrl: [],
      platformName: [null, [Validators.required, Validators.minLength(3),  xmlInjectionValidator]],
      platformShortName: [null, [Validators.required, Validators.minLength(2),  xmlInjectionValidator]],
      description: [null, [Validators.required, Validators.maxLength(200), xmlInjectionValidator]],
      active: [null,[Validators.requiredTrue]],
      platformHindiName: ["NA", [Validators.required,xmlInjectionValidator]],
      image: [null,Validators.required],
    });
  }

  patchFormValue() {
    this.platformForm = this.formBuilder.group({
      platformId: [this.data.platformData.id],
      imageUrl: [this.objectKey],
      platformName: [this.data.platformData.name, [Validators.required, Validators.minLength(3), xmlInjectionValidator]],
      platformShortName: [this.data.platformData.platformShortName, [Validators.required, Validators.minLength(2), xmlInjectionValidator]],
      description: [
        this.data.platformData.description,
        [Validators.required, Validators.maxLength(200),xmlInjectionValidator],
      ],
      active: [this.data.platformData.active],
      image: [],
      platformHindiName: [this.data.platformData.platformHindiName, [Validators.required, xmlInjectionValidator]],
    });
    this.previewUrl = this.data.platformData.imageUrl;
    this.isLoading = false;
  }

  /**
   * Edits Platform
   * @param updatedPlatformData
   */
  editPlatform(
    updatedPlatformData: Platform,
    formDirective: FormGroupDirective
  ) {
    // if (this.platformNameList.includes(updatedPlatformData.platformName)) {
    //   this.toastr.error("Platform with given name already exists", "ERROR");
    //   return;
    // }
    this.isLoading = true;
    if (this.data.action === "EDIT") {
      updatedPlatformData.platformId = this.data.platformData.id;
      updatedPlatformData.imageUrl = this.objectKey;
      this.platformService.editPlatform(updatedPlatformData).subscribe(
        (response) => {
          let responseData: InternalResponse = response;
          handleApiResponse(responseData, this.messageService, this.toastr);
          this.dialogRef.close(true);
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
    } else {
      updatedPlatformData.imageUrl = this.objectKey;
      this.platformService.addNewPlatform(updatedPlatformData).subscribe(
        (response) => {
          let responseData: InternalResponse = response;
          handleApiResponse(responseData, this.messageService, this.toastr);
          this.isLoading = false;
          this.dialogRef.close(true);
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
    }
    this.platformForm.reset();
    formDirective.resetForm();
  }

  getErrorPlatformName() {
    return this.platformForm.get("platformName").hasError("required")
      ? "Platform name is required."
      : this.platformForm.get("platformName").hasError("minlength")
        ? "Minimum 3 character required."
        : this.platformForm.get("platformName").hasError("sqlInjection")
          ? "SQL/XML/Script pattern not allowed. Please check."
          : this.platformForm.get("platformName").hasError("xmlInjection")
            ? "SQL/XML/Script pattern not allowed. Please check."
            : "";
  }

  getErrorPlatformShortName() {
    return this.platformForm.get("platformShortName").hasError("required")
      ? "Platform short name is required."
      : this.platformForm.get("platformShortName").hasError("minlength")
        ? "Minimum 2 character required."
        : this.platformForm.get("platformShortName").hasError("sqlInjection")
          ? "SQL/XML/Script pattern not allowed. Please check."
          : this.platformForm.get("platformShortName").hasError("xmlInjection")
            ? "SQL/XML/Script pattern not allowed. Please check."
            : "";
  }

  getErrorPlatformHindiName() {
    return this.platformForm.get("platformHindiName").hasError("required")
      ? "Platform hindi name is required"
      : this.platformForm.get("platformHindiName").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : this.platformForm.get("platformHindiName").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }

  getErrorDescription() {
    return this.platformForm.get("description").hasError("required")
      ? "Description is required."
      : this.platformForm.get("description").hasError("maxlength")
        ? "Maximum 200 character allowed."
        : this.platformForm.get("description").hasError("sqlInjection")
          ? "SQL/XML/Script pattern not allowed. Please check."
          : this.platformForm.get("description").hasError("xmlInjection")
            ? "SQL/XML/Script pattern not allowed. Please check."
            : "";
  }

  loadImageAsync(fileInput) {
    return new Promise((resolve, reject) => {
      this.fileData = <File>fileInput.target.files[0];
      let img = new Image()
      img.src = window.URL.createObjectURL(<File>fileInput.target.files[0])
      img.onload = () => {
        if ((img.width < 300 || img.height < 300) ||  (img.width > 500 || img.height > 500) ) {
          this.fileInputRef.nativeElement.value = null;
          this.fileData = null
          this.previewUrl = null
          this.imageDimensionErr = 'red';
          this.toastr.error('Please upload a jpg / jpeg / png image with dimension between 300 x 300 to 500 x 500', 'ERROR')
          reject({status:'failed'});
        } else {
          this.imageDimensionErr = '#c18e30'
          resolve(this.fileData);
        }
      }
    });
  }

  async fileProgress(fileInput: any) {
   await this.loadImageAsync(fileInput)
   .then(status=>{
    this.preview();
   })
  }

  preview() {
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    this.contentType = this.fileData.type;
    this.objectKey = "dsplatform/images/" + this.fileData.name;
    this.putPresignedUrl(this.contentType, this.objectKey);

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  /**
   * Image Upload
   * @param objectKey
   */
  putPresignedUrl(contentType: string, objectKey: string) {
    this.isLoading = true;
    this.platformService.putPresignedUrl(contentType, objectKey).subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        this.preSignedUrl = responseData.success_data;
        this.s3UploadImage(contentType, this.fileData, this.preSignedUrl);
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  /**
   * Image Upload using HttpHeader
   * @param contentType ,
   */
  s3UploadImage(contentType, fileData, preSignedUrl) {
    this.platformService
      .s3UploadImage(contentType, fileData, preSignedUrl)
      .subscribe(
        (response) => {
          let responseData: InternalResponse = response;
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
  }

  /**
   *  Get Image
   * @param objectKey
   */
  getPresignedImageUrl(objectKey: string) {
    this.isLoading = true;

    this.platformService.getPresignedImageUrl(objectKey).subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        this.finalImageUrl = responseData.success_data;
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
