export const user_image =
  "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50";
export const dashboard_folder_image = "assets/images/Folder.svg";
export const dashboard_operator_image = "assets/images/Operator.svg";
export const dashboard_machine_image = "assets/images/Machines.svg";
export const dashboard_timeline_image = "assets/images/timeline.svg";
export const add_project_info_image =
  "assets/images/add_project_information.png";
export const add_machine_information =
  "assets/images/add_machine_information.png";
export const authorization_key: string = "Basic bGl2ZWxpbms6fGleZXxpXms=";
export const content_type: string = "application/json";
//export const emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailregex: RegExp = /^[a-zA-Z0-9._-]+@jcb.com$/;
export const emailregexJCB: RegExp = /^[a-zA-Z0-9._-]+@jcb.com$/;
export const emailregexContus: RegExp = /^[a-zA-Z0-9._-]+@contus.in$/;
export const emailregexNccpentest: RegExp = /^[a-zA-Z0-9._-]+@20230414.p105815.nccpentest.com$/;
export const sqlInjectionPattern: RegExp = /(\s*([\0\b\'\"\n\r\t\%\_\\]*\s*(((select\s*.+\s*[*]+)|(insert\s*.+\s*into\s*.+)|(update\s*.+\s*set\s*.+)|(delete\s*.+\s*from\s*.+)|(drop\s*.+)|(truncate\s*.+)|(alter\s*.+)|(exec\s*.+)|(\s*(all|any|not|and|between|in|like|or|some|contains|containsall|containskey)\s*.+[\=\>\<=\!\~]+.+)|(let\s+.+[\=]\s*.*)|(begin\s*.*\s*end)|(\s*[\/\*]+\s*.*\s*[\*\/]+)|(\s*(\-\-)\s*.*\s+)|(\s*(contains|containsall|containskey)\s+.*)))(\s*[\;]\s*)*)+)/i
export const xmlInjectionPattern: RegExp = /(<.[^(><.)]+>)/;
export const jsInjectionPattern:RegExp =  /(\s*([\0\b\'\"\n\r\t\%\_\\]*\s*((document\.)|(window\.)|(alert\()|(confirm\()|(prompt\())(\s*[\;]\s*)*)+)/i
export const login_page_image: string =
  "assets/images/jcb-home.jpg";
// export const login_page_image: string =
//   "assets/images/305lc-tracked-excavator.jpg";
// export const login_page_image: string = "assets/images/super-loader.jpg";
export const login_url = "https://endurance.jcb.com/auth";
export const registration_url =
  "https://endurance.jcb.com/auth/register";
export const resetPasswordUrl =
  "https://endurance.jcb.com/auth/reset-password";
export const activateUserUrl ="https://endurance.jcb.com/auth/activate-user"
  // "https://endurancetesting.jcbdigital.in/auth/activate-user";
export const project_count_image = "assets/images/ico_project.svg";
export const machine_count_image = "assets/images/ico_excavator.svg";
export const hours_count_image = "assets/images/ico_hrs.svg";
export const concern_count_image = "assets/images/ico_error.svg";
export const diesel_count_image = "assets/images/ico_gas.svg";
export const edit_image = "assets/images/ico_edit.svg";
export const archive_image = "assets/images/ico_archive.svg";
export const dashboard_image = "assets/images/ico_dashboard.svg";
export const question_image = "assets/images/ico_question.svg";
export const Search_image = "assets/images/ico_Search.svg";
export const settings_image = "assets/images/ico_settings.svg";
export const ico_user_image = "assets/images/ico_user.svg";
export const userm_image = "assets/images/ico_userm.svg";
export const arrow_back = "assets/images/arrow_back.svg";
export const ico_adduser = "assets/images/ico_adduser.svg";
export const ico_driversheet = "assets/images/ico_driversheet.svg";
export const ico_component = "assets/images/ico_component.svg";
export const ico_project_wiki = "assets/images/ico_project_wiki.svg";
export const ico_dutycycle = "assets/images/ico_dutycycle.svg";
export const ico_review = "assets/images/ico_review.svg";
export const web_design = "assets/images/web_design.svg";
export const ico_excavator = "assets/images/ico_excavator.svg";
export const ico_member = "assets/images/ico_member.svg";
export const ico_comment = "assets/images/ico_comment.svg";
export const ico_fault = "assets/images/ico_fault.svg";
export const ico_report = "assets/images/ico_report.svg";
export const view_image = "assets/images/view.svg";
export const remove_image = "assets/images/remove.png";
export const qr_code_image = "assets/images/ico_qr_code.svg";
export const question_illustration = "assets/images/question_illustration.svg";
export const success_image = "assets/images/success.jpg";
export const failure_image = "assets/images/failure.jpg";
export const video_image = "assets/images/video_icon.svg"
export const pdf_image ="assets/images/pdf_icon.svg"
export const download_icon = "assets/images/download_icon.svg"
