import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["../shared-style.css"]
})
export class NotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
