import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DashboardService } from "src/app/modules/dashboard/dashboard.service";
import {
  InternalResponse,
  CommonList,
  INTERNAL_RESPONSE_STATUS,
  Model,
} from "src/app/services";
import { handleApiResponse } from "src/app/config/helper.function";
import { HttpErrorResponse } from "@angular/common/http";
import { PlatformService } from "../../platform.service";
import { MessagesService } from "src/app/shared/messages-service/messages.service";
import { sqlInjectionValidator ,xmlInjectionValidator} from "src/app/config/custom-validator.directive";

@Component({
  selector: "app-edit-model",
  templateUrl: "./edit-model.component.html",
  styleUrls: ["../../../style.css"],
})
export class EditModelComponent implements OnInit {
  isLoading: boolean = false;
  isChecked: boolean = false;
  title: string;
  modelForm: FormGroup;
  buttonName: string;
  platformList: CommonList[];
  machineService: any;
  modelList: any;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditModelComponent>,
    public platformService: PlatformService,
    public messageService: MessagesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.title = this.data.title;
  this.modelList= this.data.modelData;
    this.buttonName = this.data.buttonName;
    this.createForm();
    if (this.data.action === "EDIT") {
      this.patchFormValue();
    }
    this.getPlatformNameList();
  }

  createForm() {
    this.modelForm = this.formBuilder.group({
      modelId: [null],
      modelName: [null, [Validators.required, Validators.minLength(2),xmlInjectionValidator]],
      description: [null,[xmlInjectionValidator]],
      platformId: [null, [Validators.required]],
      active: [null, [Validators.requiredTrue]],
    });
  }

  patchFormValue() {
    this.modelForm = this.formBuilder.group({
      modelId: [this.data.modelData.modelId],
      modelName: [this.data.modelData.modelName,[Validators.required, Validators.minLength(2),xmlInjectionValidator]],
      description: [this.data.modelData.description,[xmlInjectionValidator]],
      platformId: [this.data.modelData.platformId,[Validators.required]],
      active: [this.data.modelData.active],
    });
    this.isLoading = false;
  }

  /**
   * Edits Models
   * @param updatedModelData
   */
  editModel(updatedModelData: Model, formDirective: FormGroupDirective) {
    this.isLoading = true;
    if (this.data.action === "EDIT") {
      updatedModelData.modelId = this.data.modelData.modelId;
      this.platformService.editModel(updatedModelData).subscribe(
        (response) => {
          let responseData: InternalResponse = response;
          handleApiResponse(responseData, this.messageService, this.toastr);
          this.dialogRef.close(true);
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
    } else {
      console.log(this.modelList);
      if (
        this.modelList.findIndex(item => updatedModelData.modelName.toLowerCase() === item.modelName.toLowerCase()) !== -1
      ) {
        this.toastr.error(
          "Model with this name already exists",
          "Error"
        );
        return;
      }
      else{
        this.platformService.addNewModel(updatedModelData).subscribe(
          (response) => {
            let responseData: InternalResponse = response;
            handleApiResponse(responseData, this.messageService, this.toastr);
            this.isLoading = false;
            this.dialogRef.close(true);
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.message);
          }
        );
      }
      
    }
    this.modelForm.reset();
    formDirective.resetForm();
  }

  /**
   * Gets platform name list
   */
  getPlatformNameList() {
    this.platformService.getPlatformList().subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          this.platformList = response.success_data.platforms;
          this.platformList = this.platformList.filter(
            (pl) => pl.active === true
          );
        } else {
          response.code != 401 &&
            this.toastr.error(response.error_message, "ERROR");
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  getErrorModelName() {
    return this.modelForm.get("modelName").hasError("required")
      ? "Model Name is required."
      : this.modelForm.get("modelName").hasError("minlength")
      ? "Minimum 2 character required."
      :this.modelForm.get("modelName").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.modelForm.get("modelName").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }
  getErrorDescription() {
    return this.modelForm.get("description").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.modelForm.get("description").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }

  getPlatformError() {
    return this.modelForm.get("platformId").hasError("required")
      ? "Platform is required."
      : "";
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
