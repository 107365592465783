import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["../shared-style.css"],
})
export class ConfirmComponent {
  public title: string;
  public message: string;
  public note: string;
  public buttonName: string;

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>) {}
  stay(){
    this.dialogRef.close({ response: "stay on page" });
  }
 leave(){
  this.dialogRef.close({ response: "leave page" });
 }
}
