import { AbstractControl } from '@angular/forms';
import { jsInjectionPattern, sqlInjectionPattern,xmlInjectionPattern,emailregexContus,emailregexJCB} from './const';

 export function sqlInjectionValidator(control: AbstractControl): {[key:string]:boolean | null}{
    const inputValue:string = control.value
    if(sqlInjectionPattern.test(inputValue) || jsInjectionPattern.test(inputValue)){
       return {sqlInjection:true}
    }else{
      return null
    }
  }

  export function jsInjectionValidator(control: AbstractControl): {[key:string]:boolean | null}{
    const inputValue:string = control.value
    if(jsInjectionPattern.test(inputValue)){
       return {jsInjection:true}
    }else{
      return null
    }
  }


 export function xmlInjectionValidator(control: AbstractControl): {[key:string]:boolean | null}{
  const inputValue:string = control.value
  if(xmlInjectionPattern.test(inputValue)){
     return {xmlInjection:true}
  }else{
    return null
  }
}


export function emailIdValidator(control: AbstractControl): {[key:string]:boolean | null}{
  if(control.value !== null){
    const inputValue:string = control.value.trim()
    if(emailregexContus.test(inputValue) || emailregexJCB.test(inputValue)){
       return null
    }else{
      return {emailIdValidator:true}
    }
  }
  
}
 
 

  