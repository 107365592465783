import { Component, OnInit,OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { handleApiResponse } from "src/app/config/helper.function";
import { MatDialogRef, MatDialog } from "@angular/material";
import { HttpErrorResponse } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  InternalResponse,
  DriverSheetDetails,
  QuestionAnswer,
  DriverSheetReviewed,
  DriverSheetSubmitted,
} from "src/app/services";
import { DashboardService } from "src/app/modules/dashboard/dashboard.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/modules/auth/auth.service";
import { ico_excavator, edit_image, view_image } from "src/app/config/const";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { ViewFaultDetailsComponent } from "../view-fault-details/view-fault-details.component";
import { EditFaultDetailsComponent } from "../edit-fault-details/edit-fault-details.component";
import { ViewCommentsComponent } from "../view-comments/view-comments.component";
import { EditCommentsComponent } from "../edit-comments/edit-comments.component";
import { ConfirmService } from "../../confirm-dialog/confirm.service";
import { switchMap } from "rxjs/operators";
import { MessagesService } from "../../messages-service/messages.service";
import {xmlInjectionValidator,jsInjectionValidator} from "src/app/config/custom-validator.directive";
import { QuestionBankService } from "src/app/modules/question-bank/question-bank.service";
import { INTERNAL_RESPONSE_STATUS } from "src/app/services";

@Component({
  selector: "app-empty-driver-sheet",
  templateUrl: "./empty-driver-sheet.component.html",
  styleUrls: ["../../shared-style.css"],
})
export class EmptyDriverSheetComponent implements OnInit {
  @ViewChild('dataToExport', { static: false }) public dataToExport: ElementRef;
  constructor(
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<EmptyDriverSheetComponent>,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    public confirmService: ConfirmService,
    public messageService: MessagesService,
    private questionBankService: QuestionBankService,

  ) {}

  public title: string;
  public data;
  public buttonTitle: string;
  public isViewProject:boolean;
  public faultId: string;
  public ico_excavator: string;
  public jcb_logo: string;
  public questionCategoryList: any;
  formGroup: FormGroup;
  hoursFormGroup: FormGroup;
  questionList: QuestionAnswer[];
  faultList: any;
  commentList: any;
  edit_image: string;
  view_image: string;
  remove_image: string;
  date: string;
  loginInUserRole: string;
  private userSub: Subscription;
  testingHours: number;
  screenWidth: any;
  scoreOptions: number[];
  permission: string[];
  questionTypeList:any;
  isLoading:boolean = false

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.data = this.dialogRef.componentInstance.data;
    this.date = moment(this.data.createdOn).format("YYYY-MM-DD");
    this.ico_excavator = ico_excavator;
    this.edit_image = edit_image;
    this.view_image = view_image;
    this.jcb_logo = "assets/images/jcb_logo.png";
    this.remove_image = "assets/images/remove.png";
    this.faultList = this.dialogRef.componentInstance.data.projectFaultList;
    this.commentList = this.dialogRef.componentInstance.data.comments;
    this.questionCategoryList =
      this.dialogRef.componentInstance.data.answersByCategory;
    this.userSub = this.authService.user.subscribe((user) => {
      if (user) {
        this.loginInUserRole = user.roleId;
        this.permission = user.appPermissions;
      }
    });
    this.testingHoursValidation(
      this.dialogRef.componentInstance.data.startHours,
      this.dialogRef.componentInstance.data.endHours
    );
    
    this.createForm(this.questionCategoryList);
    this.scoreOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  }

 

  onDismiss(): void {
    this.dialogRef.close();
  }

  createForm(questionCategories) {
    var categoryArr = [];

    for (var i = 0; i < questionCategories.length; i++) {

      if(this.isViewProject){
        questionCategories[i].questions.sort(function( a, b ) {
          return Number(a.order) - Number(b.order);
        });
      }else{
        questionCategories[i].answers.sort(function( a, b ) {
          return Number(a.order) - Number(b.order);
        });
      }
     
      categoryArr.push(this.appendCategory(questionCategories[i]));
    }
    this.formGroup = this.formBuilder.group({
      categories: this.formBuilder.array(categoryArr),
    });

    this.hoursFormGroup = this.formBuilder.group({
      startHours: [
        {value: this.dialogRef.componentInstance.data.startHours, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
      endHours: [
        {value: this.dialogRef.componentInstance.data.endHours, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
      testingHoursCompleted: [
        { value: this.testingHours, disabled: true },
        Validators.required,
      ],
      fuelFilledQuantity: [
        {value: this.dialogRef.componentInstance.data.fuelFilledQuantity, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
      adBlueQuantity: [
        {value: this.dialogRef.componentInstance.data.adBlueQuantity, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
      hmrAtFuelFilled: [
        {value: this.dialogRef.componentInstance.data.hmrAtFuelFilled, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
      hmrAtAdBlueFilled: [
        {value: this.dialogRef.componentInstance.data.hmrAtAdBlueFilled, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
    });
  }

  appendCategory(questionCategory): FormGroup {
    var questionArr = [];
    if(this.isViewProject){
      for (var j = 0; j < questionCategory.questions.length; j++) {
        questionArr.push(this.appendQuestion(questionCategory.questions[j]));
      }
    }else{
      for (var j = 0; j < questionCategory.answers.length; j++) {
        questionArr.push(this.appendQuestion(questionCategory.answers[j]));
      }
    }
    
    return this.formBuilder.group({
      title: [questionCategory.questionCategoryName],
      questions: this.formBuilder.array(questionArr),
    });
  }

  appendQuestion(question): FormGroup {
    return this.formBuilder.group({
      questionName: [this.isViewProject ? question.name : question.questionName],
      answer: [{value: question.answer, disabled: this.permission.includes('AP13') ? false : true}],
      levelDistance:[question.levelDistance],
      comment: [{value: question.comment, disabled: this.permission.includes('AP13') ? false : true },[xmlInjectionValidator]],
      driverSheetAnsweredId: [question.driverSheetAnsweredId],
      questionId: [question.questionId],
      score: [{value: null, disabled: this.permission.includes('AP13') ? false : true }],
      questionType: [question.questionType],
      mediaUrl: [question.mediaUrl],
    });
  }
  formValid(){
   return false
  }

  getErrorComment(categoryIndex,questioIndex) {
     return this.formGroup.get("categories").get([categoryIndex]).get("questions").get([questioIndex]).get("comment").hasError("jsInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.formGroup.get("categories").get([categoryIndex]).get("questions").get([questioIndex]).get("comment").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }


  viewFault(fault: any) {
    const dialogRef = this.dialog.open(ViewFaultDetailsComponent, {
      data: fault,
    });
  }

  editFault(fault: any) {
    const dialogRef = this.dialog.open(EditFaultDetailsComponent, {
      data: fault,
    });
  }

  deleteFault(faultId: string) {
    this.confirmService
      .confirm(
        "Delete Fault",
        "Are you Sure you want to delete this Fault?",
        "Note that by deleting this Fault will be disabled accross the application, and the data, files and information associated with it will become unavailable",
        "DELETE NOW"
      )
      .pipe(
        switchMap((res) => {
          if (res === true) {
            return this.dashboardService.deleteFault(faultId);
          }
        })
      )
      .subscribe(
        (response) => {
          this.success();
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
          this.messageService.openDialog("Error", "Delete did not happen.");
        }
      );
  }

  viewComments(comment: any) {
    const dialogRef = this.dialog.open(ViewCommentsComponent, {
      data: comment,
    });
  }

  editComments(comment: any) {
    const dialogRef = this.dialog.open(EditCommentsComponent, {
      data: comment,
    });
  }

  deleteComments(driverSheetSubmittedCommentsId: string) {
    this.confirmService
      .confirm(
        "Delete Comment",
        "Are you Sure you want to delete this Comment?",
        "Note that by deleting this Comment will be disabled accross the application, and the data, files and information associated with it will become unavailable",
        "DELETE NOW"
      )
      .pipe(
        switchMap((res) => {
          if (res === true) {
            return this.dashboardService.deleteComment(
              driverSheetSubmittedCommentsId
            );
          }
        })
      )
      .subscribe(
        (response) => {
          this.success();
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
          this.messageService.openDialog("Error", "Delete did not happen.");
        }
      );
  }

  private success() {
    this.messageService.openDialog(
      "Success",
      "Database updated successfully! Please refresh the page"
    );
  }

  reviewDriverSheet(reviewData: any, hoursData: any) {
    let formValid = true

    for(let i=0;i < this.formGroup['controls']['categories']['controls'].length;i++){
      for(let j=0;j < this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'].length;j++){
        if(this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors'] !== null){
          if( this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors']['jsInjection'] || this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors']['xmlInjection']){
            formValid = false
            break;
          }
        }
      }
    }

    if(formValid){
    if (this.testingHours === 0 || this.testingHours < 0) {
      this.messageService.openDialog(
        "ERROR",
        "Updation Failed. Testing Hours Completed Cannot be 0 or less than 0 hours"
      );
    } else if (this.testingHours > 24) {
      this.messageService.openDialog(
        "ERROR",
        "Updation Failed. Testing Hours Completed Cannot be greater than 24 hours"
      );
    } else {
      let answers = [];
      for (var i = 0; i < reviewData.categories.length; i++) {
        for (var j = 0; j < reviewData.categories[i].questions.length; j++) {
          answers.push(reviewData.categories[i].questions[j]);
        }
      }
      let newData: DriverSheetReviewed = {
        driverSheetId: this.dialogRef.componentInstance.data.driverSheetId,
        driverSheetSubmittedId:
          this.dialogRef.componentInstance.data.driverSheetSubmittedId,
        projectMachineId:
          this.dialogRef.componentInstance.data.projectMachineId,
        active: true,
        adBlueQuantity: hoursData.adBlueQuantity,
        climate: this.dialogRef.componentInstance.data.climate,
        deviceId: this.dialogRef.componentInstance.data.deviceId,
        endHours: hoursData.endHours,
        fuelFilledQuantity: hoursData.fuelFilledQuantity,
        language: this.dialogRef.componentInstance.data.language,
        reviewComment: "NA",
        reviewed: true,
        reviewedOn: this.dialogRef.componentInstance.data.reviewedOn,
        shiftId: this.dialogRef.componentInstance.data.shiftId,
        startHours: hoursData.startHours,
        temperature: this.dialogRef.componentInstance.data.temperature,
        testingHoursCompleted: this.testingHours,
        userId: this.dialogRef.componentInstance.data.userId,
        vin: this.dialogRef.componentInstance.data.vin,
        hmrAtAdBlueFilled: hoursData.hmrAtAdBlueFilled,
        hmrAtFuelFilled: hoursData.hmrAtFuelFilled,
        answers: answers.map((answer) => {
          return {
            driverSheetAnsweredId: answer.driverSheetAnsweredId,
            questionId: answer.questionId,
            comment: answer.comment,
            answer: answer.answer,
            mediaUrl: answer.mediaUrl,
            score: answer.score,
          };
        }),
      };

      this.dashboardService.reviewDriverSheet(newData).subscribe(
        (response) => {
          let responseData: InternalResponse = response;
          handleApiResponse(responseData, this.messageService, this.toastr);
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
    }
  }else{
    this.toastr.error("SQL/XML/Script pattern not allowed. Please check.", "ERROR");
  }
  }

  updateDriverSheet(updateddata: any, hoursData: any) {

    let formValid = true

    for(let i=0;i < this.formGroup['controls']['categories']['controls'].length;i++){
      for(let j=0;j < this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'].length;j++){
        if(this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors'] !== null){
          if( this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors']['jsInjection'] || this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors']['xmlInjection']){
            formValid = false
            break;
          }
        }
      }
    }
    if(formValid){
      if (this.testingHours === 0 || this.testingHours < 0) {
        this.messageService.openDialog(
          "ERROR",
          "Updation Failed. Testing Hours Completed Cannot be 0 or less than 0 hours"
        );
      } else if (this.testingHours > 24) {
        this.messageService.openDialog(
          "ERROR",
          "Updation Failed. Testing Hours Completed Cannot be greater than 24 hours"
        );
      } else {
        let answers = [];
        for (var i = 0; i < updateddata.categories.length; i++) {
          for (var j = 0; j < updateddata.categories[i].questions.length; j++) {
            answers.push(updateddata.categories[i].questions[j]);
          }
        }
        let newData: DriverSheetSubmitted = {
          driverSheetId: this.dialogRef.componentInstance.data.driverSheetId,
          driverSheetSubmittedId:
            this.dialogRef.componentInstance.data.driverSheetSubmittedId,
          projectMachineId:
            this.dialogRef.componentInstance.data.projectMachineId,
          active: true,
          adBlueQuantity: hoursData.adBlueQuantity,
          climate: this.dialogRef.componentInstance.data.climate,
          deviceId: this.dialogRef.componentInstance.data.deviceId,
          endHours: hoursData.endHours,
          fuelFilledQuantity: hoursData.fuelFilledQuantity,
          language: this.dialogRef.componentInstance.data.language,
          reviewComment: "NA",
          shiftId: this.dialogRef.componentInstance.data.shiftId,
          startHours: hoursData.startHours,
          temperature: this.dialogRef.componentInstance.data.temperature,
          testingHoursCompleted: this.testingHours,
          userId: this.dialogRef.componentInstance.data.userId,
          vin: this.dialogRef.componentInstance.data.vin,
          hmrAtAdBlueFilled: hoursData.hmrAtAdBlueFilled,
          hmrAtFuelFilled: hoursData.hmrAtFuelFilled,
          answers: answers.map((answer) => {
            return {
              driverSheetAnsweredId: answer.driverSheetAnsweredId,
              questionId: answer.questionId,
              comment: answer.comment,
              answer: answer.answer,
              mediaUrl: answer.mediaUrl,
              score: answer.score,
            };
          }),
        };
  
        this.dashboardService.updateDriverSheetSubmitted(newData).subscribe(
          (response) => {
            let responseData: InternalResponse = response;
            handleApiResponse(responseData, this.messageService, this.toastr);
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.message);
          }
        );
      }
    }else{
      this.toastr.error("SQL/XML/Script pattern not allowed. Please check.", "ERROR");
    }
  
  }

  testingHoursValidation(startHours, endHours): string {
    let testingHoursCompleted = endHours - startHours;
    this.testingHours = Math.round(testingHoursCompleted * 10) / 10;
    if (testingHoursCompleted === 0) {
      return "Testing Hours Completed Cannot be 0";
    } else if (testingHoursCompleted < 25) {
      return "SUCCESS";
    } else {
      return "Testing Hours Completed Cannot be greater than 24";
    }
  }

  onkeyUpDown(){
    return false;
  }

printDriverSheet() {
      
  var contents = this.getContentToPrint(this.data);
  var writeContent = `<html>
    <head>
      <style>
      @page {
        size: A4 portrait;
        margin: 10px 0;
      }
      body {
        margin: 10mm; 
      }
      table{
        border-collapse: collapse;
      }
      .table-th, .table-td {
        text-align: left;
        font-size: 12px;
        border: 1px solid #e5e5e5;
        padding: 5px;
      }
      .ds-score-label {
        text-align: left;
        letter-spacing: 0;
        color: #565656;
        opacity: 1;
        padding-right: 12px;
      }
      .ds-input-box-style {
        line-height: initial !important;
        border: 0.5px solid #cfa76193 !important;
        max-width: 100%;
        height: 22px;
        border-radius: 3px;
      }
      
      .App {
        font-family: sans-serif;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      
      .slider-wrapper {
        width: 200px;
        height: 30px;
        display: flex;
        border: 1.5px solid black;
        border-radius: 3px;
      }
      .low-section {
        width: 25px;
        height: 100%;
        border: none;
        border-right: 2px solid red;
      }
      .low-active {
        background-color: red;
      }
      .slider-section {
        flex: 1;
        position: relative;
      }
      .slider-active {
        width: 0%;
        height: 100%;
        background-color: skyblue;
      }
      .min-label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;
        font-size: 14px;
      }
      .max-label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 3px;
        font-size: 14px;
      }
      .high-section {
        width: 25px;
        height: 100%;
        border: none;
        border-left: 2px solid red;
      }
      .high-active {
        background-color: red;
      }
      
      </style>
      <title>`+ this.data.projectName + '-' + moment().format("DD-MM-YYYY-HH-mm-ss")  +`</title>
    </head>
    <body onload="window.print();window.close()">
    ` + contents + `
    </body>
  </html>`;
  var win = window.open();
  self.focus();
  win.document.open();
  win.document.write(writeContent);
  win.document.close();
  
  win.print();
  win.close();

}




getContentToPrint(data: any){
var answerValue: any;
var content = `<div class="dialog-container-fault">            
<table style="width: 100%;">
<tr>
  <td class="table-td" style="text-align:left; border: none;">
    <img src="/assets/images/jcb_logo.png" />
  </td>
  <td class="table-td" style="text-align:center; border: none;">
    Daily Test Driver Report Sheet
    <h3 style="margin: 0;">`+ data.projectName +`</h3>
  </td>
  
</tr>
</table>

<table style="width: 100%; margin: 15px 0;">
<tr><td colspan="7" class="table-td">Test Driver</td></tr>
<tr><th colspan="7" class="table-th">&nbsp;</th></tr>
<tr>
    <td class="table-td">Shift Start Hmr</td>
    <td class="table-td">Shift End Hmr</td>
    <td class="table-td">Total Shift Hmr Run</td>
    <td class="table-td">Ad Blue Quantity (lit)</td>
    <td class="table-td">Fuel Filled Quantity (lit)</td>
    <td class="table-td">HMR At Ad Blue Filled</td>
    <td class="table-td">HMR At Fuel Filled</td>
</tr>
<tr>
    <th class="table-th">&nbsp;</th>
    <th class="table-th">&nbsp;</th>
    <th class="table-th">&nbsp;</th>
    <th class="table-th">&nbsp;</th>
    <th class="table-th">&nbsp;</th>
    <th class="table-th">&nbsp;</th>
    <th class="table-th">&nbsp;</th>
</tr>
</table>

<div style="display: flex; margin: 15px 0;">
<table style="width:50%; float:left;">
    <tr><td colspan="2" class="table-td">Machine PIN</td></tr>
    <tr style="margin-bottom: 10px;"><th colspan="2" class="table-th">`+ data.machine.vin +`</th></tr>
    <tr>
        <td class="table-td">Machine Model</td>
        <td class="table-td">Machine Platform</td>
    </tr>
    <tr>
        <th class="table-th">&nbsp;</th>
        <th class="table-th">`+ data.platformName +`</th>
    </tr>
</table>

<table style="width:49%; float:right; margin-left: 15px;">
    <tr><td colspan="3" class="table-td">Test Plant</td></tr>
    <tr style="margin-bottom: 10px;"><th colspan="3" class="table-th">`+ data.testSiteLocationName +`</th></tr>
    <tr>
        <td class="table-td" style="width:28%;">Shift</td>
        <td class="table-td" style="width:28%;">Climate</td>
        <td class="table-td" style="width:44%;">Temperature (DEG C)</td>
    </tr>
    <tr>
        <th class="table-th" style="width:28%;">&nbsp;</th>
        <th class="table-th" style="width:28%;">&nbsp;</th>
        <th class="table-th" style="width:44%;">&nbsp;</th>
    </tr>
</table>
</div>`;

data.answersByCategory.forEach((category) => {
  content += `<table style="width: 100%; margin: 15px 0;">
  <tr>
  <th colspan="3" style="font-size: 12px; padding: 5px; text-align: left; background-color: #dee2e6;">`+ category.questionCategoryName +`</th>
  </tr>`;
  let type = this.isViewProject ? category.questions : category.answers; 
  type.forEach((answers) => {
    let questionName = this.isViewProject ? answers.name : answers.questionName; 

  if(answers.questionType === '3'){
    answerValue = `<span><input type="text"  class="ds-input-box-style"></span>`;
  } else if(answers.questionType === '4'){
    answerValue = `<span>
    <input type="radio" required disabled readonly
    style="line-height: initial !important; margin-top: 4%;" value="0">
    <label class="ds-score-label" style="padding-left: 12px;">No</label>
    <input type="radio" required disabled readonly
    style="line-height: initial !important; margin-top: 4%;" value="1">
    <label class="ds-score-label" style="padding-left: 12px;">Yes</label>
    <input type="radio" required disabled readonly
    style="line-height: initial !important; margin-top: 4%;" value="2">
    <label class="ds-score-label" style="padding-left: 12px;">N/A</label>
    <input type="radio" required disabled readonly
        style="line-height: initial !important; margin-top: 4%;" value="3">
    <label class="ds-score-label" style="padding-left: 12px;">N/C</label>
    </span>`
  } else if(answers.questionType === '2'){
    answerValue = `<span><input type="number" style="line-height: initial !important; height: 22px;"/></span>`;
  }else if(answers.questionType === '5') {
    answerValue = `
    <div>

    <div class="App">
      <div class="slider-wrapper">
        <div class="low-section"></div>
        <div class="slider-section">
          <div class="slider-active"></div>
          <div class="min-label">Min</div>
          <div class="max-label">Max</div>
        </div>
        <div class="high-section"></div>
      </div>
    </div>
    `;
  } else {
    if(answers.questionType === '1'){
      answerValue = `<span> <label class="ds-score-label">Score</label>
      <input type="number" min="0" max="10" maxlength="2"
      required disabled readonly class="ds-input-box-style"> </span>`;
    }
  }

  content += `<tr *ngFor="let question of category.controls.questions.controls; let j =index;">
    <td class="table-td" style="width: 30%; text-align: left;">`+questionName +`</td>
    <td class="table-td" style="width: 30%; text-align: left;">`+answerValue+` </td>
    <td class="table-td" style="width: 40%; text-align: left;"><span></span></td>
  </tr>`;
});
content += `</table>`;
});
content += `<table style="width: 100%; margin: 15px 0;" >
<tr>
    <th colspan="2" style="font-size: 12px; padding: 5px; border: 1px solid #e5e5e5; text-align: left; background-color: #dee2e6;">Faults & Comments</th>
</tr>`;
content += `<tr>
<td class="table-td" style="text-align: left; padding:150px">&nbsp;&nbsp;&nbsp</td>
</tr>`;        
    content += `</table>`;
    
content += `</div>`;
return content;
}







  
}
