import { INTERNAL_RESPONSE_STATUS } from "../services";

export function handleResponse(response, toastr) {
  if (response.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
    response.success_message ? toastr.success(response.success_message) : "";
  } else {
    response.code != 401 && toastr.error(response.error_message, "ERROR");
  }
}

export function handleApiResponse(response, messageService, toastr) {
  if (response.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
    // messageService.openDialog("SUCCESS", response.success_message);
    toastr.success(response.success_message, "SUCCESS");
  } else {
    response.code != 401 && toastr.error(response.error_message, "ERROR");
    // messageService.openDialog("ERROR", response.error_message);
  }
}
