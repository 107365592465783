import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material.module";
import { MatMenuModule, MAT_DATE_LOCALE } from "@angular/material";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import {
  NotFoundComponent,
  MessagesComponent,
  ConfirmComponent,
  HeaderComponent,
  DigitOnlyDirective,
  FooterComponent,
  SelectProjectComponent,
  SubHeaderComponent,
  EmptyDriverSheetComponent,
  SelectMachineComponent,
  ViewProjectWikiComponent,
} from ".";
import { SidebarNavComponent } from "./sidebar-nav/sidebar-nav.component";
import { SharedRoutingModule } from "./shared-routing.module";
import { SessionExpireComponent } from "./session-expire/session-expire.component";
import { MenuListItemComponent } from "./sidebar-nav/menu-list-item/menu-list-item.component";
import { FilterPipe } from "../helpers/filter.pipe";
import { NewUserPopupComponent } from "./new-user-popup/new-user-popup.component";
import { EditComponentPopupComponent } from "./edit-component-popup/edit-component-popup.component";
import { FaultDetailsComponent } from "./fault-details/fault-details.component";
import { DriverSheetDetailsComponent } from "./driver-sheet-details/driver-sheet-details.component";
import { ViewFaultDetailsComponent } from "./driver-sheet-details/view-fault-details/view-fault-details.component";
import { EditFaultDetailsComponent } from "./driver-sheet-details/edit-fault-details/edit-fault-details.component";
import { EditCommentsComponent } from "./driver-sheet-details/edit-comments/edit-comments.component";
import { ViewCommentsComponent } from "./driver-sheet-details/view-comments/view-comments.component";
import { ExpandImageComponent } from "./expand-image/expand-image.component";
import { UpToDateComponent } from '../cache/up-to-date.component';
import { ViewQRCodeComponent } from "../modules/machine/view-qr-code/view-qr-code.component";
import { AddProjectWikiPopupComponent } from "./add-project-wiki-popup/add-project-wiki-popup.component";
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    NotFoundComponent,
    MessagesComponent,
    ConfirmComponent,
    SidebarNavComponent,
    HeaderComponent,
    DigitOnlyDirective,
    SessionExpireComponent,
    MenuListItemComponent,
    FooterComponent,
    SelectProjectComponent,
    SubHeaderComponent,
    FilterPipe,
    NewUserPopupComponent,
    EditComponentPopupComponent,
    AddProjectWikiPopupComponent,
    ViewQRCodeComponent,
    FaultDetailsComponent,
    DriverSheetDetailsComponent,
    EmptyDriverSheetComponent,
    SelectMachineComponent,
    ViewFaultDetailsComponent,
    EditFaultDetailsComponent,
    EditCommentsComponent,
    ViewCommentsComponent,
    ExpandImageComponent,
    ViewProjectWikiComponent
    // UpToDateComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedRoutingModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule
  ],
  exports: [
    CommonModule,
    NotFoundComponent,
    SidebarNavComponent,
    HeaderComponent,
    FooterComponent,
    DigitOnlyDirective,
    MatMenuModule,
    SubHeaderComponent,
    FilterPipe,
  ],
  // providers: [{ provide: MAT_DATE_LOCALE, useValue: "en-GB" }],
  entryComponents: [
    MessagesComponent,
    ConfirmComponent,
    SelectProjectComponent,
    NewUserPopupComponent,
    EditComponentPopupComponent,
    AddProjectWikiPopupComponent,
    ViewQRCodeComponent,
    FaultDetailsComponent,
    DriverSheetDetailsComponent,
    EmptyDriverSheetComponent,
    SelectMachineComponent,
    ViewFaultDetailsComponent,
    EditFaultDetailsComponent,
    ViewCommentsComponent,
    EditCommentsComponent,
    ExpandImageComponent,
    ViewProjectWikiComponent
  ],
})
export class SharedModule {}
