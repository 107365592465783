import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-expand-image',
  templateUrl: './expand-image.component.html',
  styleUrls: ["../shared-style.css"]
})
export class ExpandImageComponent implements OnInit {
  public imageUrl: string;
  constructor(public dialogRef: MatDialogRef<ExpandImageComponent>) { }

  ngOnInit() {
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
