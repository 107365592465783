import * as CryptoJS from "crypto-js";

export function encryptData(data: string) {
  try {
    return CryptoJS.AES.encrypt(data, "secret key 123").toString();
  } catch (e) {
    console.log(e);
  }
}

export function decryptData(data: string) {
  try {
    const bytes = CryptoJS.AES.decrypt(data, "secret key 123");
    if (bytes.toString()) {
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return data;
  } catch (e) {
    console.log(e);
  }
}

// Encrypt a message using DES
export function encryptDes(message:string, key:string) {
  var keyHex = CryptoJS.enc.Utf8.parse(key);
  var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

// Decrypt a message using DES
export  function decryptDes(ciphertext:string, key:string) {
  var keyHex = CryptoJS.enc.Utf8.parse(key);
  var decrypted = CryptoJS.DES.decrypt(
    {ciphertext: CryptoJS.enc.Base64.parse(ciphertext),iv:null,salt:null},
    keyHex,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}