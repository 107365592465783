import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, properties: string[]): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter((item) => {
      var itemFound: Boolean;
      for (let i = 0; i < properties.length; i++) {
        if (i < properties.length) {
          if (
            item[properties[i]] &&
            item[properties[i]]
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
          ) {
            itemFound = true;
            break;
          }
        } else {
          for (let j = 0; j < item.machineDetailsList.length; j++) {
            if (
              item.machineDetailsList[j].vin
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) !== -1
            ) {
              itemFound = true;
              break;
            }
          }
        }
      }
      return itemFound;
    });
  }
}
