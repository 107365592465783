
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import {
  handleError,
} from "src/app/services";

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  constructor(private http: HttpClient) { }
  downloadFileFromS3(bucketName: string, key: string): Observable<any> {
    const url = `https://${bucketName}.s3.amazonaws.com/${key}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      catchError((unauthorizedResponse: any) => {
        return handleError(unauthorizedResponse, url);
      }),
      map((response) => {
        return response;
      })
    ); 
  }
}


