// SERVER ADDRESS TO BE FETCHED FROM app.config.json
import { environment } from "../../environments/environment";

// API'S FOR USER AND APP CONFIG RELATED STUFF
export const LOGIN = environment.baseUrl + "/user/login";
export const ACTIVATE_USER_REGISTRATION =
  environment.baseUrl + "/user/activateuser";
export const REGISTRATION = environment.baseUrl + "/user/register";
export const LOGOUT = environment.baseUrl + "/user/logout";
export const FORGOT_PASSWORD = environment.baseUrl + "/user/forgotpassword";
export const GET_PASSWORD = environment.baseUrl + "/user/request-user";
export const AUTHENTICATION_OTP = environment.baseUrl + "/user/otp-verification";
export const RESENT_OTP = environment.baseUrl + "/user/resend-otp";
export const RESET_PASSWORD = environment.baseUrl + "/user/resetpassword";
export const UPDATE_USER_ROLE = environment.baseUrl + "/user/user-roles";
export const UPDATE_USER = environment.baseUrl + "/user/users";
export const UPDATE_OPERATOR = environment.baseUrl + "/user/operators";
export const UPDATE_COMPONENTS = environment.baseUrl + "/dashboard/component";
export const REGISTER_OPERATOR =
  environment.baseUrl + "/user/register-operator";
export const GET_LOCATIONS = environment.baseUrl + "/user/location-names";

// API'S FOR USER DASHBOARD RELATED STUFF
export const GET_ALL_DASHBOARD_COUNTS =
  environment.baseUrl + "/dashboard/all-dashboard-counts";

// API'S FOR QUICKSIGHT RELATED STUFF
export const GET_QUICKSIGHT_DASHBOARD_URL =
  environment.baseUrl + "/common/getembedurl";

// API'S FOR USER PROJECT RELATED STUFF
export const GET_PROJECT_LIST = environment.baseUrl + "/dashboard/projects";
export const GET_QUESTION_DETAILS = environment.baseUrl + "/common/questioncategory/list";
export const GET_PROJECT_DETAILS =
  environment.baseUrl + "/dashboard/project-details";
export const GET_PROJECT_MACHINE_LIST =
  environment.baseUrl + "/project/project-machine-list";
export const GET_PROJECT_FAULT_LIST =
  environment.baseUrl + "/project/project-fault-list";
  export const GET_PROJECT_COMPONENTS_LIST =
  environment.baseUrl + "/dashboard/component-list";
export const GET_PROJECT_COMMENTS =
  environment.baseUrl + "/project/driver-sheet-comments-list";
export const PROJECT_MACHINE_API =
  environment.baseUrl + "/project/project-machine";
export const PROJECT_USER_API = environment.baseUrl + "/project/project-user";
export const PROJECT_ENDURANCE_API =
  environment.baseUrl + "/project/project-endurance-cycle";
export const GET_PROJECT_DETAILS_LIST =
  environment.baseUrl + "/dashboard/project-list-details";
export const GET_PROJECT_WIKI_LIST =
  environment.baseUrl + "/project/wiki/";
export const DELETE_PROJECT_WIKI =
  environment.baseUrl + "/project/wiki/";
export const ADD_PROJECT_WIKI_LIST =
  environment.baseUrl + "/project/wiki/";
export const UPDATE_PROJECT_WIKI =
  environment.baseUrl + "/project/wiki/";
  export const CLONE_PROJECT_WIKI =
  environment.baseUrl + "/project/wiki/clone";
export const PROJECT_ACTIVATE =
  environment.baseUrl + "/project/project-activate";
export const PROJECT_MACHINE_ACTIVATE =
  environment.baseUrl + "/project/project-machine-activate";

// API'S FOR USER MACHINES RELATED STUFF
export const MACHINE_API = environment.baseUrl + "/dashboard/machines";
export const GET_MACHINES_LIST =
  environment.baseUrl + "/dashboard/model-machine-names";

export const GET_MACHINES_USERS =
  environment.baseUrl + "/dashboard/machines-users";

// API'S FOR USER DASHBOARD LIST ITEM'S RELATED STUFF
export const GET_VIN_LIST = environment.baseUrl + "/dashboard/machine-names";
export const GET_ACTIVITY_LIST =
  environment.baseUrl + "/dashboard/activity-names";
export const GET_MODEL_LIST = environment.baseUrl + "/dashboard/model-names";
export const GET_ALL_USERS = environment.baseUrl + "/dashboard/getallusers";
export const GET_PROJECT_NAME_LIST =
  environment.baseUrl + "/dashboard/project-names";
export const GET_USER_LIST = environment.baseUrl + "/dashboard/user-names";
export const GET_USER_ROLES = environment.baseUrl + "/user/role-names";
export const GET_OPERATORS_NAMES =
  environment.baseUrl + "/dashboard/operator-names";
export const GET_ROLE_USER_LIST =
  environment.baseUrl + "/dashboard/role-user-names";

// API'S FOR USER OPERATORS RELATED STUFF
export const GET_OPERATORS_LIST = environment.baseUrl + "/dashboard/operators";

// API'S FOR USER DEVICES RELATED STUFF
export const DEVICES_API = environment.baseUrl + "/common/devices";
export const GET_DEVICE_LIST = environment.baseUrl + "/dashboard/device-names";

// API'S FOR USER PLANT RELATED STUFF
export const PLANT_API = environment.baseUrl + "/dashboard/plants";
export const GET_PLANT_LIST = environment.baseUrl + "/dashboard/plant-names";
export const GET_PLANT_DETAILS_LIST =
  environment.baseUrl + "/project/plant-details-list";

// API'S FOR  USER PLATFORM RELATED STUFF
export const PLATFORM_API = environment.baseUrl + "/dashboard/platforms";
export const GET_PLATFORM_LIST =
  environment.baseUrl + "/dashboard/platform-names";

// API'S FOR USER DRIVER SHEETS RELATED STUFF
export const DRIVER_SHEETS_API = environment.baseUrl + "/common/driversheets";
export const DRIVER_SHEET_QUESTIONS_API =
  environment.baseUrl + "/common/driversheetquestions";
export const DS_SUBMITTED_API =
  environment.baseUrl + "/common/driversheetsubmitted";
export const DS_REVIEWED_API =
  environment.baseUrl + "/common/driversheetreviewed";
export const DS_SUBMITTED_ANSWERS_API =
  environment.baseUrl + "/common/driversheetsubmittedanswers";
export const QUESTIONS_API = environment.baseUrl + "/dashboard/questions";
export const QUESTIONS_LIST_API =
  environment.baseUrl + "/dashboard/questions-list";
export const QUESTIONS_NAME_LIST =
  environment.baseUrl + "/dashboard/question-names";
export const QUESTIONS_TYPE_LIST =
  environment.baseUrl + "/dashboard/question-type-names";
export const QUES_CATEGORIES_API =
  environment.baseUrl + "/dashboard/questioncategories";
export const QUES_CATEGORIES_LIST_API =
  environment.baseUrl + "/dashboard/questioncategories-list";
export const DS_TEMPLATES_API =
  environment.baseUrl + "/dashboard/driversheettemplates";
export const DS_TEMPLATE_LIST =
  environment.baseUrl + "/dashboard/template-names";
export const DS_SUBMITTED_DETAILS_LIST =
  environment.baseUrl + "/project/driver-sheet-submitted-list";

// API'S FOR REPORT RELATED STUFF
export const AVERAGE_DRIVER_SCORE =
  environment.baseUrl + "/report/average-driver-score";
export const DUTYCYCLE_PERFORMANCE =
  environment.baseUrl + "/report/dutycycle-performed";
export const FAULT_TREND = environment.baseUrl + "/report/fault-trend";
export const FUEL_CONSUMPTION =
  environment.baseUrl + "/report/fuel-consumption";
export const OVERALL_DRIVER_SCORE =
  environment.baseUrl + "/report/overall-driver-score";
export const ENDURANCE_TRACKER =
  environment.baseUrl + "/report/endurance-tracker";
export const DAILY_NMC_FAULT =
  environment.baseUrl + "/report/graph/daily-nmc-fault";
export const DAILY_FAULT = environment.baseUrl + "/report/timeline/daily-fault";
export const PLANT_PROJECT_HOURS =
  environment.baseUrl + "/report/plant-project-hours";
export const PROJECT_HOURS =
  environment.baseUrl + "/report/timeline/project-hours";
export const PLATFORM_PROJECT_COUNT =
  environment.baseUrl + "/report/platform-project-count";
export const PLATFORM_PROJECT_HOURS =
  environment.baseUrl + "/report/platform-project-hours";
export const USER_DETAILS_LIST =
  environment.baseUrl + "/project/user-details-list";
// Question Bank
export const QUESTION_BANK = environment.baseUrl + "/project/question-bank";
export const GET_FAULT = environment.baseUrl + "/common/faults";
export const DELETE_COMPONENT = environment.baseUrl + "/dashboard/component";
export const GET_COMMENT =
  environment.baseUrl + "/common/driversheetsubmittedcomments";

// Machine Details List
export const GET_MACHINE_DETAILS_LIST =
  environment.baseUrl + "/project/machine-details-list";

// Odometer API's
export const ODOMETER_API = environment.baseUrl + "/project/odometers";

// Image Upload
export const GET_PRESIGNED_URL =
  environment.baseUrl + "/fileservice/getrequestpresignedurl";
export const PUT_PRESIGNED_URL =
  environment.baseUrl + "/fileservice/putrequestpresignedurl";

// Failure Components List
export const COMPONENTS_API =
  environment.baseUrl + "/failures/get-all-components";
export const COMPONENTS_FAILURE_API = environment.baseUrl + "/failures/failure";
export const COMPONENTS_PROBLEM_AGGREGATE =
  environment.baseUrl + "/failures/problemaggregate";
export const COMPONENTS1_API =
  environment.baseUrl + "/failures/component-level1";
export const COMPONENTS2_API =
  environment.baseUrl + "/failures/component-level2";
export const COMPONENTS3_API =
  environment.baseUrl + "/failures/component-level3";
export const COMPONENTS4_API =
  environment.baseUrl + "/failures/component-level4";
export const GET_ALL_FAILURE_API =
  environment.baseUrl + "/failures/get-all-failure";
export const GET_ALL_PROBLEM_AGGREGATE_API =
  environment.baseUrl + "/failures/get-all-problemaggregate";
export const GET_ALL_COMPONENTS1_API =
  environment.baseUrl + "/failures/get-all-componentLevel1";
export const GET_ALL_COMPONENTS2_API =
  environment.baseUrl + "/failures//get-all-componentLevel2";
export const GET_ALL_COMPONENTS3_API =
  environment.baseUrl + "/failures/get-all-componentLevel3";
export const GET_ALL_COMPONENTS4_API =
  environment.baseUrl + "/failures/get-all-componentLevel4";

// Models
export const MODELS_API = environment.baseUrl + "/project/models";
export const GET_MODEL_DETAILS_LIST =
  environment.baseUrl + "/project/model-details-list";

// Reports
export const PROJECT_REPORT_API =
  environment.baseUrl + "/driversheetreport/generate-project-report";
export const PROJECT_MACHINE_REPORT_API =
  environment.baseUrl + "/driversheetreport/generate-project-machine-report";
