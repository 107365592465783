import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { InternalResponse, Fault } from "src/app/services";
import { ToastrService } from "ngx-toastr";
import { PlantService } from "src/app/modules/plant";
import { DashboardService } from "src/app/modules/dashboard/dashboard.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { HttpErrorResponse } from "@angular/common/http";
import { handleApiResponse } from "src/app/config/helper.function";
import { MessagesService } from "../../messages-service/messages.service";

@Component({
  selector: "app-edit-fault-details",
  templateUrl: "./edit-fault-details.component.html",
  styleUrls: ["../../shared-style.css"],
})
export class EditFaultDetailsComponent implements OnInit {
  isLoading: boolean = false;
  faultForm: FormGroup;
  screenWidth: any;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private plantService: PlantService,
    private dashboardService: DashboardService,
    public messageService: MessagesService,
    public dialogRef: MatDialogRef<EditFaultDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.createForm();
  }

  createForm() {
    this.faultForm = this.formBuilder.group({
      status: [this.data.status, Validators.required],
      description: [this.data.description, Validators.required],
      failureHr: [this.data.failureHr, Validators.required],
      failureType: [this.data.failureType, Validators.required],
      fault: [this.data.fault, Validators.required],
      component: [this.data.component, Validators.required],
      problemAggregate: [this.data.problemAggregate],
      safe: [this.data.safe],
      nMC: [this.data.nMC],
    });
  }

  /**
   * Edit Faults
   * @param updatedFaultData
   */
  updateFault(updatedUserData: Fault, formDirective: FormGroupDirective) {
    this.isLoading = true;
    let evidence: string[] = null;
    if (this.data.evidenceUrl != null) {
      for (var n = 0; n < this.data.evidenceUrl.split(",").length; n++) {
        evidence.push(this.data.evidenceUrl.split(",")[n]);
      }
    }
    this.data.description = updatedUserData.description;
    this.data.component = updatedUserData.component;
    this.data.problemAggregate = updatedUserData.problemAggregate;
    this.data.fault = updatedUserData.fault;
    this.data.failureType = updatedUserData.failureType;
    this.data.failureHr = updatedUserData.failureHr;
    this.data.status = updatedUserData.status;
    this.data.safe = updatedUserData.safe;
    this.data.nMC = updatedUserData.nMC;

    updatedUserData.faultId = this.data.faultId;
    updatedUserData.driverSheetSubmittedId = this.data.driverSheetSubmittedId;
    updatedUserData.repairAction = this.data.repairAction;
    updatedUserData.hrsDiffPreviousFault = this.data.hrsDiffPreviousFault;
    updatedUserData.lostHrs = this.data.lostHrs;
    updatedUserData.action = this.data.action;
    updatedUserData.openDate = this.data.openDate;
    updatedUserData.closeDate = this.data.closeDate;
    updatedUserData.evidenceUrl = evidence;
    updatedUserData.dutyCycle = this.data.dutyCycle;
    updatedUserData.vin = this.data.vin;
    this.dashboardService.addNewFault(updatedUserData).subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        handleApiResponse(responseData, this.messageService, this.toastr);
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
    this.faultForm.reset();
    formDirective.resetForm();
  }

  getStatusError() {
    return this.faultForm.get("status").hasError("required")
      ? "Status is required"
      : "";
  }

  getDescriptionError() {
    return this.faultForm.get("description").hasError("required")
      ? "Description is required"
      : "";
  }

  getFailureHrError() {
    return this.faultForm.get("failureHr").hasError("required")
      ? "Failure Hr is required"
      : "";
  }

  getFailureTypeError() {
    return this.faultForm.get("failureType").hasError("required")
      ? "Failure Type is required"
      : "";
  }

  getFaultError() {
    return this.faultForm.get("fault").hasError("required")
      ? "Fault is required"
      : "";
  }

  getComponentError() {
    return this.faultForm.get("component").hasError("required")
      ? "Component is required"
      : "";
  }

  getProblemAggregateError() {
    return this.faultForm.get("problemAggregate").hasError("required")
      ? "Problem Aggregate is required"
      : "";
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
