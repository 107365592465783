import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
@Component({
  selector: 'app-select-machine',
  templateUrl: './select-machine.component.html',
  styleUrls: ["../../shared-style.css"]
})
export class SelectMachineComponent {
  @ViewChild('myInput',{static:false}) myInput: ElementRef;
  public title: string;
  public data: any;
  vin: 'NA';


  constructor(public dialogRef: MatDialogRef<SelectMachineComponent>,
    private router: Router,
    private route: ActivatedRoute) { }

  onDismiss(): void {
    this.dialogRef.close();
  }



  formControl = new FormControl('');
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    // this.myInput.nativeElement.blur();
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: any): string[] {
    let filterValue; 

    if(typeof value !== "string"){
      filterValue = value["vin"].toLowerCase();
    }else{
      filterValue = value.toLowerCase();
    }

    return this.data.filter(option => option["vin"].toLowerCase().includes(filterValue));
  }
  OnClick(option){  
        this.vin = option.vin;
  }

  displayFn(project): string {
    return project && project.vin ? project.vin : '';

  }
}
