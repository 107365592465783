import { DashboardService } from "./../../modules/dashboard/dashboard.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/modules/auth/auth.service";
import {
  arrow_back
} from "src/app/config/const";

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ["../shared-style.css"]
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  title: string;
  loginInUser: string;
  private userSub: Subscription;
  arrow_back: string;
  screenWidth: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.dashboardService.title.subscribe(response => {
      this.title = response;
      this.arrow_back = arrow_back;
    });
    this.userSub = this.authService.user.subscribe(user => {
      if (user) {
        this.loginInUser = user.firstName.concat(" " + user.lastName);
      }
    });
  }

  backToDashboard() {
    this.router.navigate(["../dashboard"]);
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

}
