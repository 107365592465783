import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import {
  ACTIVATE_USER_REGISTRATION,
  GET_LOCATIONS,
  GET_DEVICE_LIST,
  USER_DETAILS_LIST,
  GET_PLATFORM_LIST,
  GET_PLANT_DETAILS_LIST,
  GET_MODEL_DETAILS_LIST
} from "./../../config/backend.api.urls";

import {
  UserLoginRequest,
  InternalResponse,
  createUrl,
  getHeader,
  handleError,
  modifyResponse,
  UserRegistrationRequest,
  ForgetPasswordRequest,
  UserLoginResponse,
  BACKEND_RESPONSE_STATUS,
  ResetPasswordRequest,
  ActivateAccountRequest,
  UserLoginAuthenticationRequest
} from "src/app/services";
import {
  LOGIN,
  REGISTRATION,
  FORGOT_PASSWORD,
  LOGOUT,
  RESET_PASSWORD,
  AUTHENTICATION_OTP,
  RESENT_OTP
} from "src/app/config/backend.api.urls";
import { Router } from "@angular/router";
import { encryptData ,decryptData} from "src/app/helpers/crypto";
import { User } from "src/app/models";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  user = new BehaviorSubject<User>(null);
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService
  ) { }

  /**
   * FUNCTION TO LOGIN WITH VALID CREDENCIAL
   * @param loginDetails HOLDS THE REQUIRED LOGIN REQUEST DETAILS
   * @returns user: OBJECT CONTAIN ALL THE DATA OF LOGGED-IN USER
   */
  loginUser(loginDetails: UserLoginRequest) {
    return this.http
      .post(createUrl(LOGIN), loginDetails, {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, LOGIN);
        }),
        map(response => {
          if (response.status === BACKEND_RESPONSE_STATUS[2]) {
            return modifyResponse(response);
          }
          this.handleAuthentication(response, loginDetails);
        })
      );
  }

  /**
   * Activates user registration
   * @param activateAccountRequest
   * @returns user registration
   */
  activateUserRegistration(
    activateAccountRequest: ActivateAccountRequest
  ): Observable<InternalResponse> {
    return this.http
      .post(createUrl(ACTIVATE_USER_REGISTRATION), activateAccountRequest, {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, REGISTRATION);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * FUNCTION REGISTER NEW USER
   * @param reqDetails HOLDS THE REQUIRED REGISTER DETAILS
   * @returns SUCCESSFUL/FAILDED MESSAGE
   */
  registerUser(
    reqDetails: UserRegistrationRequest
  ): Observable<InternalResponse> {
    return this.http
      .post(createUrl(REGISTRATION), reqDetails, {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, REGISTRATION);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  handleUnathorized() {
    this.user.next(null);
  }

  /**
   * FUNCTION TO LOGOUT THE USER
   * @returns SUCCESSFUL/FAILDED MESSAGE
   */
  logout() {
    return this.http.get(createUrl(LOGOUT), { headers: getHeader() }).pipe(
      catchError((unauthorizedResponse: any) => {
        return handleError(unauthorizedResponse, LOGOUT);
      }),
      map(response => {
        if (response.status === BACKEND_RESPONSE_STATUS[0]) {
          this.user.next(null);
        }
        return modifyResponse(response);
      })
    );
  }

  /**
   * FUNCTION THAT HELPS TO FORGETED PASSWORD USER
   * @param userName: VALID EMAIL OF USER
   * @returns SUCCESSFUL/FAILDED MESSAGE
   */
  forgotPassword(reqDetails: ForgetPasswordRequest) {
    return this.http
      .post(createUrl(FORGOT_PASSWORD), reqDetails, { headers: getHeader() })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, FORGOT_PASSWORD);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   *FUNCTION THAT HELPS TO RESET PASSWORD USER
   * @param reqDetails HOLDS THE REQUIRED REQUEST DETAILS
   * @returns  SUCCESSFUL/FAILDED MESSAGE
   */
  resetPassword(reqDetails: ResetPasswordRequest) {
    return this.http
      .post(createUrl(RESET_PASSWORD), reqDetails, { headers: getHeader() })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, FORGOT_PASSWORD);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

   /**
   * FUNCTION THAT HELPS TO AUTHENTICATION OTP FOR LOGIN USER
   * @returns SUCCESSFUL/FAILDED MESSAGE
   */
    authenticationOTP(reqDetails: UserLoginAuthenticationRequest) {
      let url = createUrl(AUTHENTICATION_OTP);
      url = `${url}?otp=${reqDetails.otp}&email=${reqDetails.email}`
      return this.http
        .post(url, reqDetails, {
          headers: getHeader()
        })
        .pipe(
          catchError((unauthorizedResponse: any) => {
            return handleError(unauthorizedResponse, AUTHENTICATION_OTP);
          }),
          map(response => {
            if (response.status === BACKEND_RESPONSE_STATUS[2]) {
              return modifyResponse(response);
            }
            // const userData = JSON.parse(localStorage.getItem('userData'));
            const userData = JSON.parse(decryptData(sessionStorage.getItem('userData')));

            const user = new User(
              response.accessToken,
              userData.bucketAccesskey,
              userData.bucketSecretkey,
              userData.appPermissions,
              false,
              userData.country,
              userData.email,
              userData.firstName,
              userData.image,
              userData.lastName,
              userData.roleId,
              userData.userId,
              []
            );
            sessionStorage.setItem("userData", encryptData(JSON.stringify(user)))
            // localStorage.setItem("userData", JSON.stringify(user));
            this.user.next(user);
            this.router.navigate(["/dashboard"]);
          })
        );
    }

    resentOTP(reqDetails: UserLoginAuthenticationRequest) {
      let url = createUrl(RESENT_OTP);
      url = `${url}?email=${reqDetails.email}`
      return this.http
        .post(url, reqDetails, {
          headers: getHeader()
        })
        .pipe(
          catchError((unauthorizedResponse: any) => {
            return handleError(unauthorizedResponse, AUTHENTICATION_OTP);
          }),
          map(response => {
            if (response.status === BACKEND_RESPONSE_STATUS[2]) {
              return modifyResponse(response);
            }
          })
        );
    }

  /**
   * FUNCTION THAT HELPS THE USER TO AUTO LOGIN ON REFRESH IF THE TOKEN IS VALID
   * @returns
   */
  autoLogin() {
    const userData: {
      accessToken: string;
      bucketAccesskey: string;
      bucketSecretkey: string;
      appPermissions: string[],
      twoFactorAuthenticationReq: boolean,
      country: string;
      email: string;
      firstName: string;
      image: string;
      lastName: string;
      roleId: string;
      userId: string;
    } = JSON.parse(decryptData(sessionStorage.getItem("userData")));

    // } = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      return;
    }
    const loadedUser = new User(
      userData.accessToken,
      userData.bucketAccesskey,
      userData.bucketSecretkey,
      userData.appPermissions,
      userData.twoFactorAuthenticationReq,
      userData.country,
      userData.email,
      userData.firstName,
      userData.image,
      userData.lastName,
      userData.roleId,
      userData.userId,
      []
    );
    if (loadedUser.token) {
      this.user.next(loadedUser);
    } else {
      //console.log("Session Expire");
    }
  }

  /**
   * FUNCTION TO HANDLE THE RESPONSE OF LOGIN REST API CALL
   * @param responsedata HOLD THE RESPONSE OF LOGIN REST API CALL
   */
  handleAuthentication(
    responsedata: UserLoginResponse,
    loginDetails: UserLoginRequest
  ) {
    const user = new User(
      responsedata.accessToken,
      responsedata.bucketAccesskey,
      responsedata.bucketSecretkey,
      responsedata.appPermissions,
      responsedata.twoFactorAuthenticationReq,
      responsedata.country,
      responsedata.email,
      responsedata.firstName,
      responsedata.image,
      responsedata.lastName,
      responsedata.roleId,
      responsedata.userId,
      []
    );
    sessionStorage.setItem("userData", encryptData(JSON.stringify(user)));

    // localStorage.setItem("userData", JSON.stringify(user));
    if (loginDetails.checked) {
      this.cookieService.set("email", loginDetails.email,environment.cookieExpires,environment.cookiePath,environment.cookieDomain,environment.cookieSecure);
      this.cookieService.set("password", encryptData(loginDetails.password),environment.cookieExpires,environment.cookiePath,environment.cookieDomain,environment.cookieSecure);
      this.cookieService.set("checked", loginDetails.checked.toString(),environment.cookieExpires,environment.cookiePath,environment.cookieDomain,environment.cookieSecure);
    }
    if(!responsedata.twoFactorAuthenticationReq && responsedata.accessToken !== null){
      this.user.next(user);
      this.router.navigate(["/dashboard"]);
      //this.toastr.success("Login Successful!");
    } else {
      this.router.navigate(["auth/authentication"]);
    }
  }

  getLocation() {
    return this.http
      .get(createUrl(GET_LOCATIONS), { headers: getHeader() })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_LOCATIONS);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  getDevice() {
    return this.http
      .get(createUrl(GET_DEVICE_LIST), { headers: getHeader() })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_DEVICE_LIST);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  getUserDetailsList() {
    return this.http
      .get(createUrl(USER_DETAILS_LIST), {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, USER_DETAILS_LIST);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  getPlatformDetailsList() {
    return this.http
      .get(createUrl(GET_PLATFORM_LIST), {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PLATFORM_LIST);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  getPlantDetailsList() {
    return this.http
      .get(createUrl(GET_PLANT_DETAILS_LIST), {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PLANT_DETAILS_LIST);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  getModelDetailsList() {
    return this.http
      .get(createUrl(GET_MODEL_DETAILS_LIST), {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PLANT_DETAILS_LIST);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

}
