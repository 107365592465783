import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { MediaMatcher } from "@angular/cdk/layout";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthService } from "src/app/modules/auth/auth.service";
import {
  InternalResponse,
  NavItem,
  INTERNAL_RESPONSE_STATUS,
} from "src/app/services";
import { handleResponse } from "src/app/config/helper.function";
import { user_image } from "src/app/config/const";

@Component({
  selector: "app-sidebar-nav",
  templateUrl: "./sidebar-nav.component.html",
  styleUrls: ["./sidebar-nav.component.css"],
})
export class SidebarNavComponent implements OnInit {
  mobileQuery: MediaQueryList;
  imageUrl = user_image;
  fillerNav = [
    {
      icon: "assets/images/ico_dashboard.svg",
      route: "/dashboard",
      tooltip: "Dashboard",
    },
    {
      icon: "assets/images/project.png",
      route: "/archive-project",
      tooltip: "Projects",
    },
    {
      icon: "assets/images/Question.svg",
      route: "/question-bank",
      tooltip: "Question Bank",
    },
    {
      icon: "assets/images/ico_userm.svg",
      route: "/add-user",
      tooltip: "User Setting",
    },
    {
      icon: "assets/images/ico_settings.svg",
      route: "/handle-roles",
      tooltip: "Setting",
    },
  ];

  userFillerNav = [
    {
      icon: "assets/images/ico_dashboard.svg",
      route: "/dashboard",
      tooltip: "Dashboard",
    },
    {
      icon: "assets/images/project.png",
      route: "/archive-project",
      tooltip: "Projects",
    }
  ];

  mobileFillerNav = [
    {
      icon: "assets/images/ico_dashboard.svg",
      route: "/dashboard",
      tooltip: "Dashboard",
    },
    {
      icon: "assets/images/Question.svg",
      route: "/question-bank",
      tooltip: "Question Bank",
    },
    {
      icon: "assets/images/ico_userm.svg",
      route: "/add-user",
      tooltip: "User Setting",
    },
    {
      icon: "assets/images/ico_settings.svg",
      route: "/handle-roles",
      tooltip: "Setting",
    },
  ];

  userFilterNav = [
    {
      icon: "assets/images/operator.png",
      route: "/register-operator",
      tooltip: "Register Operator",
    },
    {
      icon: "assets/images/ico_userm.svg",
      route: "/add-user",
      tooltip: "Add User",
    },
    {
      icon: "assets/images/user_Management.svg",
      route: "/update-user",
      tooltip: "Update User",
    },
  ];

  settingFilterNav = [
    {
      icon: "assets/images/machine.png",
      route: "/machine",
      tooltip: "Machines",
    },
    {
      icon: "assets/images/plant.png",
      route: "/components",
      tooltip: "Failure Components",
    },
    {
      icon: "assets/images/platform.png",
      route: "/plant-platform",
      tooltip: "Plants, Platforms & Models",
    },
  ];

  mobileSettingFilterNav = [
    {
      icon: "assets/images/plant.png",
      route: "/components",
      tooltip: "Failure Components",
    },
    {
      icon: "assets/images/platform.png",
      route: "/plant-platform",
      tooltip: "Plants, Platforms & Models",
    },
  ];

  settingFilterNavTestEngineer = [
    {
      icon: "assets/images/machine.png",
      route: "/machine",
      tooltip: "Machines",
    },
    {
      icon: "assets/images/plant.png",
      route: "/components",
      tooltip: "Failure Components",
    },
    {
      icon: "assets/images/platform.png",
      route: "/plant-platform",
      tooltip: "Platforms & Models",
    },
  ];

  mobileSettingFilterNavTestEngineer = [
    {
      icon: "assets/images/plant.png",
      route: "/components",
      tooltip: "Failure Components",
    },
    {
      icon: "assets/images/platform.png",
      route: "/plant-platform",
      tooltip: "Platforms & Models",
    },
  ];

  private _mobileQueryListener: () => void;
  private userSub: Subscription;
  isAuthenticated: boolean = false;
  isLoading: boolean = false;
  loginInUserRole: string;
  loginInUser: string;
  public screenWidth: any;
  isTimeOut: any;
  isDefaultTimeOut: any;
  menuOpen:boolean = false;
  fullscreen: boolean = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 700px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.userSub = this.authService.user.subscribe((user) => {
      this.isAuthenticated = !user ? false : true;
      if (user) {
        this.loginInUserRole = user.roleId;
        this.loginInUser = user.firstName.concat(" " + user.lastName);
      }
    });

    this.roleValidation(this.loginInUserRole);
  }

  ngAfterViewInit(): void{ 
    const hd = document.getElementById('mat-toolbar-row');
    const sb = document.getElementById('mat-sidenav');
    const dd = document.getElementById('mat-sidenav-content');
    const tb = document.getElementById('side-bar-toolbar'); 
    // Default
    // const localFullscreen = localStorage.getItem("fullscreen");
    const localFullscreen = sessionStorage.getItem("fullscreen");

    if(localFullscreen === "1"){
      this.fullscreen = true;
      hd.classList.remove("header-class");
      sb.classList.remove("sidebar-class");
      dd.classList.remove("mat-sidenav-header")
      tb.classList.add("toolbar-index")
    } else{
      this.fullscreen = false;
      hd.classList.add("header-class");
      sb.classList.add("sidebar-class");
      dd.classList.add("mat-sidenav-header")
      tb.classList.remove("toolbar-index")
    }
    if(hd){
      hd.addEventListener('mouseover', () => {
        if(this.fullscreen){
          hd.classList.add("header-class");
          sb.classList.add("sidebar-class");
          dd.classList.add("mat-sidenav-header")
          tb.classList.remove("toolbar-index")
        }
      });

      hd.addEventListener('mouseout', () => {
        if(this.fullscreen){
          hd.classList.remove("header-class");
          sb.classList.remove("sidebar-class");
          dd.classList.remove("mat-sidenav-header")
          tb.classList.add("toolbar-index")
        }
      });
    }

    if(sb){
      sb.addEventListener('mouseover', () => {
        if(this.fullscreen){
          sb.classList.add("sidebar-class");
          hd.classList.add("header-class");
          dd.classList.add("mat-sidenav-header")
          tb.classList.remove("toolbar-index")
        }
      });

      sb.addEventListener('mouseout', () => {
        if(this.fullscreen){
          sb.classList.remove("sidebar-class");
          hd.classList.remove("header-class");
          dd.classList.remove("mat-sidenav-header")
          tb.classList.add("toolbar-index")
        }
      });
    }    
  }

  onFullScreenView(){
    const hd = document.getElementById('mat-toolbar-row');
    const sb = document.getElementById('mat-sidenav');
    const dd = document.getElementById('mat-sidenav-content');
    const tb = document.getElementById('side-bar-toolbar'); 
    if(this.fullscreen){
      this.fullscreen = false;
      hd.classList.add("header-class");
      sb.classList.add("sidebar-class");
      dd.classList.add("mat-sidenav-header");
      tb.classList.remove("toolbar-index")
    } else {
      this.fullscreen = true;
      sb.classList.remove("sidebar-class");
      hd.classList.remove("header-class");
      dd.classList.remove("mat-sidenav-header");
      tb.classList.add("toolbar-index")
    }
  }

  subMenuOpen(val:any){
    this.isTimeOut = setTimeout(() => {
      const hd = document.getElementById('mat-toolbar-row');
        const sb = document.getElementById('mat-sidenav');
        const dd = document.getElementById('mat-sidenav-content');
        const tb = document.getElementById('side-bar-toolbar'); 
      if(val === 1){
        this.menuOpen = true;
        hd.classList.add("header-class");
        sb.classList.add("sidebar-class");
        dd.classList.add("mat-sidenav-header")
        tb.classList.remove("toolbar-index")
      }else{
        this.menuOpen = false;
        hd.classList.remove("header-class");
        sb.classList.remove("sidebar-class");
        dd.classList.remove("mat-sidenav-header")
        tb.classList.add("toolbar-index")
      }
    }, 10);
  }

  logout() {
    this.isLoading = true;
    this.authService.logout().subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          this.isLoading = false;
          this.router.navigate(["/auth"]);
          // localStorage.removeItem("userData");
          sessionStorage.removeItem("userData");

        } else {
          this.isLoading = false;
          response.code != 401 &&
            this.toastr.error(response.error_message, "ERROR");
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.userSub.unsubscribe();
    clearTimeout(this.isTimeOut)
    clearTimeout(this.isDefaultTimeOut)
    // localStorage.setItem("fullscreen", this.fullscreen ? "1" : "0")
    sessionStorage.setItem("fullscreen", this.fullscreen ? "1" : "0")

  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some((h) =>
    h.test(window.location.host)
  );

  backToLogin() {
    this.router.navigate([""], { relativeTo: this.route });
  }

  roleValidation(roleId: string) {
    if (this.screenWidth < 600) {
      this.fillerNav = this.mobileFillerNav;
      this.settingFilterNav = this.mobileSettingFilterNav;

      if (roleId !== "R02" && roleId !== "R03") {
        this.fillerNav = [];
      } else {
        if (roleId === "R02") {
          this.settingFilterNav = this.mobileSettingFilterNavTestEngineer;
        }
      }
    } else {
      if (roleId !== "R02" && roleId !== "R03" && roleId !== "R00") {
        this.fillerNav = [{
          icon: "assets/images/ico_dashboard.svg",
          route: "/dashboard",
          tooltip: "Dashboard",
        }];
      } else {
        if (roleId === "R02") {
          this.settingFilterNav = this.settingFilterNavTestEngineer;
        }else if(roleId === "R00"){
         this.fillerNav=this.userFillerNav
        }
      }
    }
  }
}
