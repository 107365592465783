import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
@Component({
  selector: 'app-select-project',
  templateUrl: './select-project.component.html',
  styleUrls: ["../shared-style.css"]
})
export class SelectProjectComponent {
  public title: string;
  public data: any;
  projectId: 'NA';
  dropdownVar = 0;

  constructor(public dialogRef: MatDialogRef<SelectProjectComponent>,
    private router: Router,
    private route: ActivatedRoute) { }

  onDismiss(): void {
    this.dialogRef.close();
  }

  createProject() {
    this.dropdownVar = 0;
    this.dialogRef.close();
    this.router.navigate(["add-project"], { relativeTo: this.route });
  }

  showProjectList(value: number) {
  }


  formControl = new FormControl('');
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: any): string[] {
    let filterValue; 

    if(typeof value !== "string"){
      filterValue = value["name"].toLowerCase();
    }else{
      filterValue = value.toLowerCase();
    }

    return this.data.filter(option => option["name"].toLowerCase().includes(filterValue));
  }
  OnClick(option){
        this.projectId = option.id;
  }

  displayFn(project): string {
    return project && project.name ? project.name : '';

  }
}
