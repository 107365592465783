import { Observable } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material";
import { Injectable } from "@angular/core";

import { NewUserPopupComponent } from "./new-user-popup.component";
import { CommonList, RegisterOperatorRequest } from "src/app/services";

@Injectable({
  providedIn: "root",
})
export class NewUserPopupService {
  private dialogRef: MatDialogRef<NewUserPopupComponent>;

  constructor(private dialog: MatDialog) {}

  public confirm(
    title: string,
    registerOperatorRequest: RegisterOperatorRequest,
    buttonTitle: string
  ): Observable<any> {
    this.dialogRef = this.dialog.open(NewUserPopupComponent, {});
    this.dialogRef.componentInstance.title = title;
    this.dialogRef.componentInstance.data = registerOperatorRequest;
    this.dialogRef.componentInstance.buttonTitle = buttonTitle;

    return this.dialogRef.afterClosed();
  }

  public afterClose(): string {
    this.dialogRef.afterClosed().subscribe((result) => {});
    return this.dialogRef.componentInstance.projectId;
  }
}
