import { Component, OnInit } from "@angular/core";
import { handleApiResponse } from "src/app/config/helper.function";
import { MatDialogRef } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import {
  InternalResponse,
  INTERNAL_RESPONSE_STATUS,
  RegisterOperatorRequest,
  CommonList,
  EncrDecrService,
} from "src/app/services";
import {
  GET_USER_ROLES,
  UPDATE_OPERATOR,
  UPDATE_USER,
} from "src/app/config/backend.api.urls";
import { DashboardService } from "src/app/modules/dashboard/dashboard.service";
import { ToastrService } from "ngx-toastr";
import { RegisterOperatorService } from "src/app/modules/register-operator/register-operator.service";
import { AuthService } from "src/app/modules/auth/auth.service";
import { MessagesService } from "../messages-service/messages.service";
import { xmlInjectionValidator} from "src/app/config/custom-validator.directive";
import {decryptDes} from "src/app/helpers/crypto";

@Component({
  selector: "app-new-user-popup",
  templateUrl: "./new-user-popup.component.html",
  styleUrls: ["../shared-style.css"],
})
export class NewUserPopupComponent implements OnInit {
  registerForm: FormGroup;
  public title: string;
  public data: any;
  public buttonTitle: string;
  public projectId: string;
  addOperator: boolean = false;
  updateOperator: boolean = false;
  hide = true;
  hideEmail: boolean = false;
  hideDevice: boolean = false;
  hideRole: boolean = false;
  hidePassword: boolean = false;
  isLoading: boolean = false;
  locations: CommonList[];
  roleList: CommonList[];
  devices: CommonList[];
  countries: CommonList[];
  passwordPattern:RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-+_!@#$%^&*.,?])[A-Za-z0-9-+_!@#$%^&*.,?]{12,16}$/
  retrievedPassword:string;
  constructor(
    public dialogRef: MatDialogRef<NewUserPopupComponent>,
    private router: Router,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private registerOperatorService: RegisterOperatorService,
    private authService: AuthService,
    public messageService: MessagesService
  ) {}

  ngOnInit() {
    if (this.dialogRef.componentInstance.data === null) {
      this.createForm();
      this.addOperator = true;
    } else {
      if (this.dialogRef.componentInstance.buttonTitle.includes("Add")) {
        this.addOperator = true;
        this.updateForm(this.dialogRef.componentInstance.data, "Add");
      } else if (this.dialogRef.componentInstance.title.includes("User")) {
        this.updateOperator = false;
        this.updateForm(this.dialogRef.componentInstance.data, "User");
      } else if (this.dialogRef.componentInstance.title.includes("Operator")) {
        this.updateOperator = true;
        this.updateForm(this.dialogRef.componentInstance.data, "Operator");
      }
    }
    this.getLocations();
    this.getRoleList();
    this.getDevices();
    this.countries = [
      { id: "India", name: "India" },
      { id: "Nepal", name: "Nepal" },
    ];
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  createProject() {
    this.dialogRef.close();
    this.router.navigate(["add-user"]);
  }

   makeid(length:number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

  viewPassword(){
    let seckretkey = this.makeid(29)
   let payload ={
    request: seckretkey,
    userId:this.dialogRef.componentInstance.data.userId
    }

   
    this.dashboardService.getPassword(payload).subscribe((response)=>{
      let responseData: InternalResponse = response;
      if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
        this.retrievedPassword = decryptDes(response.success_message, seckretkey);
      } else {
        response.code != 401 &&
          this.toastr.error(response.error_message, "ERROR");
      }
    },(err: HttpErrorResponse) => {
      console.log(err.error);
      console.log(err.message);
    })
  }
  createForm() {
    this.hidePassword = true;
    this.registerForm = this.formBuilder.group({
      userId: [null],
      email: ["operator@jcb.com"],
      firstName: [null, [Validators.required, Validators.minLength(3),xmlInjectionValidator]],
      lastName: [null, [Validators.required, Validators.minLength(3),xmlInjectionValidator]],
      // country: [null, [Validators.required]],
      location: [null, [Validators.required]],
      password: ["Endurance@1234", [Validators.required, Validators.pattern(this.passwordPattern),xmlInjectionValidator]],
      deviceId: ["D00"], 
      roleId: ["R01", [Validators.required]],
      phoneNumber: [null],
      status: [null, Validators.requiredTrue],
      isBlocked:[false]
    });
  }

  updateForm(data, module: string) {
    if (module === "Operator") {
      this.hideRole = false;
      this.hideDevice = true;
      this.hidePassword = true;
    } else {
      this.hideRole = true;
    }

    let status = "true";
    if (data.status === true) {
    } else status = "false";
    this.registerForm = this.formBuilder.group({
      userId: [data.userId],
      email: [data.email],
      firstName: [data.firstName,[Validators.required, Validators.minLength(3), xmlInjectionValidator]],
      lastName: [data.lastName, [Validators.required, Validators.minLength(3),xmlInjectionValidator]],
      // country: [data.country, [Validators.required]],
      location: [data.location, [Validators.required]],
      password: ["", [Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-+_!@#$%^&*.,?])[A-Za-z0-9-+_!@#$%^&*.,?]{8,}$"),xmlInjectionValidator]],
      deviceId: [data.deviceId,[xmlInjectionValidator]],
      roleId: [data.roleId, [Validators.required]],
      phoneNumber: [data.phoneNumber],
      status: [data.status, Validators.required],
      deviceDesc: [data.deviceDesc,[xmlInjectionValidator]],
      isBlocked: [data.isBlocked === 1 ? true: false ]
    });
  }

  /**
   * Gets locations
   */
  getLocations() {
    this.isLoading = true;
    this.authService.getLocation().subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          this.locations = response.success_data.locations;
          this.isLoading = false;
        } else {
          response.code != 401 &&
            this.toastr.error(response.error_message, "ERROR");
          this.isLoading = false;
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }
  /**
   * Gets Devices
   */
  getDevices() {
    this.isLoading = true;
    this.authService.getDevice().subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          this.devices = response.success_data.devices;
          this.isLoading = false;
        } else {
          response.code != 401 &&
            this.toastr.error(response.error_message, "ERROR");
          this.isLoading = false;
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }
  /**
   * Gets role list
   */
  getRoleList() {
    this.dashboardService.getDashboardData(GET_USER_ROLES).subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          this.roleList = response.success_data.roles;
          this.roleList = this.roleList.filter(
            (r) => r.name != "JCB_Operators"
          );
        } else {
          response.code != 401 &&
            this.toastr.error(response.error_message, "ERROR");
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  /**
   * REGISTER NEW USER THROUGH TEST ENGINEER
   * @param reqDetails OLDS THE DATA FOR  REGISTER REQUEST
   * @returns  SUCCESSFUL / FAILED MESSAGE RESPONSE
   */
  onSubmit(
    reqDetails: RegisterOperatorRequest,
    formDirective: FormGroupDirective
  ) {
    console.log({reqDetails})
    if (!this.registerForm.valid) {
      return;
    }
    reqDetails.isBlocked = reqDetails.isBlocked ? 1 : 0;
    this.isLoading = true;
    if (this.addOperator) {
      reqDetails.roleId = "R01";
      if (reqDetails.deviceId === "") {
        reqDetails.deviceId = "D00";
      }
      this.registerOperatorService.registerOperator(reqDetails).subscribe(
        (response) => {
          let responseData: InternalResponse = response;
          console.log(responseData);
          if(responseData.error_message === "UserName Already Exist with the combination of location, FirstName and LastName. Please enter valid details!"){
            responseData.error_message = 'Username already exist with the combination of location, fistname and lastname. Please enter valid details!'  
          }
          handleApiResponse(responseData, this.messageService, this.toastr);
          this.isLoading = false;
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
    } else {
      if (this.updateOperator) {
        reqDetails.roleId = "R01";
        if (reqDetails.deviceId === "") {
          reqDetails.deviceId = "D00";
        }
        let apiCallUrl = UPDATE_OPERATOR;
        this.registerOperatorService
          .updateUserDetail(reqDetails, apiCallUrl)
          .subscribe(
            (response) => {
              let responseData: InternalResponse = response;
              console.log(responseData);
              handleApiResponse(responseData, this.messageService, this.toastr);
              this.isLoading = false;
            },
            (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.message);
            }
          );
      } else {
        let apiCallUrl = UPDATE_USER;
        this.registerOperatorService
          .updateUserDetail(reqDetails, apiCallUrl)
          .subscribe(
            (response) => {
              let responseData: InternalResponse = response;
              handleApiResponse(responseData, this.messageService, this.toastr);
              this.isLoading = false;
            },
            (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.message);
            }
          );
      }
    }
    formDirective.resetForm();
    this.registerForm.reset();
  }

  backToDashboard() {
    this.router.navigate(["./dashboard"]);
  }

  getErrorFirstName() {
    return this.registerForm.get("firstName").hasError("required")
      ? "First Name is required."
      : this.registerForm.get("firstName").hasError("minlength")
      ? "Minimum 3 character required."
      :this.registerForm.get("firstName").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.registerForm.get("firstName").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }

  getErrorLastName() {
    return this.registerForm.get("lastName").hasError("required")
      ? "Last Name is required."
      : this.registerForm.get("lastName").hasError("minlength")
      ? "Minimum 3 character required."
      :this.registerForm.get("lastName").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.registerForm.get("lastName").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }
  getErrorDeviceId() {
    return this.registerForm.get("deviceId").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.registerForm.get("deviceId").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }

  getErrorDeviceDesc() {
    return this.registerForm.get("deviceDesc").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.registerForm.get("deviceDesc").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }



  getErrorEmail() {
    return this.registerForm.get("email").hasError("required")
      ? "Email is required."
      : this.registerForm.get("email").hasError("pattern")
      ? "Not a valid email address (domain must be jcb.com)."
      : "";
  }

  getErrorPassword() {
    // return this.registerForm.get("password").hasError("required")
    //   ? "Password is required"
    //   : "";

    return this.registerForm.get("password").hasError("required")
      ? "Password is required."
      : this.registerForm.get("password").hasError("pattern")
      ?"Choose a password between 12 to 16 characters, one upper case, one lower case, one number, one special characters and no white space"
      : "";
  }

  // getCountryError() {
  //   return this.registerForm.get("country").hasError("required")
  //     ? "Country is required"
  //     : "";
  // }

  getLocationError() {
    return this.registerForm.get("location").hasError("required")
      ? "Location is required"
      : "";
  }

  getDeviceError() {
    return this.registerForm.get("device").hasError("required")
      ? "Device is required"
      : "";
  }
  // getStatusError() {
  //   return this.registerForm.get("status").hasError("required")
  //     ? "Status is required"
  //     : "";
  // }
}
