import { Component } from '@angular/core';
import { UpToDateBuildService } from './up-to-date.service';

@Component({
  selector: 'app-up-to-date',
  templateUrl: './up-to-date.component.html',
  styleUrls:['./up-to-date.component.css']
})
export class UpToDateComponent  {

    public buildIsUpToDate = false;

    constructor(upToDateService: UpToDateBuildService) {
        upToDateService.buildIsUpToDate.subscribe(buildIsUpToDate => {
            this.buildIsUpToDate = !buildIsUpToDate;
        });        
    } 
    siteRefresh(){
      window.location.reload()
    }
    closeNotify(){
      this.buildIsUpToDate = false
    }
}