import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import {
  createUrl,
  getHeader,
  handleError,
  modifyResponse,
  getImageHeader,
  getFileUploadHeader,
  Platform,
  Model} from "src/app/services";
import {
  PLATFORM_API,
  PUT_PRESIGNED_URL,
  GET_PRESIGNED_URL,
  GET_PLATFORM_LIST,
  MODELS_API} from "src/app/config/backend.api.urls";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class PlatformService {
  constructor(private http: HttpClient) {}

  /*************************************PLATFORM API'S********************************/
  /**
   * GET PLATFORM'S DETAILS USING ID
   * @param platformId
   * @returns
   */
  getPlatformDetails(platformId: string) {
    const params = new HttpParams().set("platformId", platformId);
    return this.http
      .get(createUrl(PLATFORM_API), {
        headers: getHeader(),
        params: params
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PLATFORM_API);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * ADD A NEW PLATFORM
   * @param newPlatformData
   * @returns
   */
  addNewPlatform(newPlatformData: Platform) {
    return this.http
      .post(createUrl(PLATFORM_API), newPlatformData, {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PLATFORM_API);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * EDIT THE EXISTING PLATFORM USING platform'ID
   * @param updatedPlatformData
   * @returns
   */
  editPlatform(updatedPlatformData: Platform) {
    const params = new HttpParams()
      .set("platformId", updatedPlatformData.platformId)
      .set("description", updatedPlatformData.description)
      .set("imageUrl", updatedPlatformData.imageUrl)
      .set("platformName", updatedPlatformData.platformName)
      .set("platformShortName", updatedPlatformData.platformShortName)
      .set("platformHindiName", updatedPlatformData.platformHindiName)
      .set("active", updatedPlatformData.active.toString());
      //.set("image", updatedPlatformData.image);

    return this.http
      .put(createUrl(PLATFORM_API), updatedPlatformData, {
        headers: getHeader(),
        params
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PLATFORM_API);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * DELETE PLATFORM USING PLATFORM'ID
   * @param platformId
   * @returns
   */
  deletePlatform(platformId: string) {
    const params = new HttpParams().set("platformId", platformId);
    return this.http
      .delete(createUrl(PLATFORM_API), {
        headers: getHeader(),
        params: params
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PLATFORM_API);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Gets platform list
   * @returns
   */
  getPlatformList() {
    return this.http
      .get(createUrl(GET_PLATFORM_LIST), {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PLATFORM_LIST);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Gets Object Key for Image Upload
   * @returns
   */
  getPresignedImageUrl(objectKey) {
    const params = new HttpParams()
      .set("objectKey", objectKey);
    return this.http
      .get(createUrl(GET_PRESIGNED_URL), {
        headers: getHeader(),
        params: params
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PRESIGNED_URL);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Gets Object Key for Image Upload
   * @returns
   */
  putPresignedUrl(contentType, objectKey) {
    const params = new HttpParams()
      .set("contentType", contentType)
      .set("objectKey", objectKey);
    return this.http
      .get(createUrl(PUT_PRESIGNED_URL), {
        headers: getImageHeader(contentType),
        params: params
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PRESIGNED_URL);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Image Upload on S3
   * @returns
   */
  s3UploadImage(contentType, fileData, preSignedUrl) {
    return this.http
      .put(createUrl(preSignedUrl), fileData,{
        headers: getFileUploadHeader(contentType)
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, preSignedUrl);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * ADD A NEW Model
   * @param newModelData
   * @returns
   */
  addNewModel(newModelData: Model) {
    return this.http
      .post(createUrl(MODELS_API), newModelData, {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, MODELS_API);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * EDIT THE EXISTING Model USING model'ID
   * @param updatedModelData
   * @returns
   */
  editModel(updatedModelData: Model) {
    const params = new HttpParams()
      .set("modelId", updatedModelData.modelId)
      .set("modelName", updatedModelData.modelName)
      .set("description", updatedModelData.description)
      .set("platformId", updatedModelData.platformId)
      .set("active", updatedModelData.active.toString());

    return this.http
      .put(createUrl(MODELS_API), updatedModelData, {
        headers: getHeader(),
        params: params
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, MODELS_API);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * DELETE MODEL USING MODEL'ID
   * @param modelId
   * @returns
   */
  deleteModel(modelId: string) {
    const params = new HttpParams().set("modelId", modelId);
    return this.http
      .delete(createUrl(MODELS_API), {
        headers: getHeader(),
        params: params
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, MODELS_API);
        }),
        map(response => {
          return modifyResponse(response);
        })
      );
  }


}
