import { Component, OnInit } from "@angular/core";
import { handleApiResponse } from "src/app/config/helper.function";
import { MatDialogRef, MatDialog } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  InternalResponse,
  DriverSheetDetails,
  QuestionAnswer,
  DriverSheetReviewed,
  DriverSheetSubmitted,
  UpdateDriverSheetReviewed,
} from "src/app/services";
import { DashboardService } from "src/app/modules/dashboard/dashboard.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/modules/auth/auth.service";
import { ico_excavator, edit_image, view_image } from "src/app/config/const";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { ViewFaultDetailsComponent } from "./view-fault-details/view-fault-details.component";
import { EditFaultDetailsComponent } from "./edit-fault-details/edit-fault-details.component";
import { ViewCommentsComponent } from "./view-comments/view-comments.component";
import { EditCommentsComponent } from "./edit-comments/edit-comments.component";
import { ConfirmService } from "../confirm-dialog/confirm.service";
import { switchMap } from "rxjs/operators";
import { MessagesService } from "../messages-service/messages.service";
import {xmlInjectionValidator,jsInjectionValidator} from "src/app/config/custom-validator.directive";
import { FaultDetailsService } from 'src/app/shared/fault-details/fault-details.service';

@Component({
  selector: "app-driver-sheet-details",
  templateUrl: "./driver-sheet-details.component.html",
  styleUrls: ["../shared-style.css"],
})
export class DriverSheetDetailsComponent implements OnInit {
  constructor(
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<DriverSheetDetailsComponent>,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    public confirmService: ConfirmService,
    public messageService: MessagesService,
    private faultDetailsService: FaultDetailsService,
  ) {}

  public title: string;
  public data: DriverSheetDetails;
  public buttonTitle: string;
  public draft:boolean;
  public faultId: string;
  public ico_excavator: string;
  public jcb_logo: string;
  public questionCategoryList: any;
  formGroup: FormGroup;
  hoursFormGroup: FormGroup;
  questionList: QuestionAnswer[];
  faultList: any;
  commentList: any;
  edit_image: string;
  view_image: string;
  remove_image: string;
  date: string;
  startDate:string;
  updatedDate:string;
  submittedDate:string;
  loginInUserRole: string;
  private userSub: Subscription;
  testingHours: number;
  screenWidth: any;
  scoreOptions: number[];
  permission: string[];
  enduranceList:any = null;
  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.data = this.dialogRef.componentInstance.data;
    this.draft = this.dialogRef.componentInstance.draft
    this.startDate = this.data.createdOn == null ? "-" :  this.data.reviewed === 'Yes'?  (this.data.submittedOn == null ? "-" : moment(this.data.submittedOn).format("YYYY-MM-DD HH:mm:ss")) :moment(this.data.createdOn).format("YYYY-MM-DD HH:mm:ss")
    this.updatedDate = this.data.lastUpdateOn == null ?"-" : moment(this.data.lastUpdateOn).format("YYYY-MM-DD HH:mm:ss")
    this.submittedDate = this.updatedDate === '-' ? this.startDate : this.data.lastSubmittedOn == null ? "-": moment(this.data.lastSubmittedOn).format("YYYY-MM-DD HH:mm:ss")
    this.date = moment(this.data.createdOn).format("YYYY-MM-DD");
    this.ico_excavator = ico_excavator;
    this.edit_image = edit_image;
    this.view_image = view_image;
    this.jcb_logo = "assets/images/jcb_logo.png";
    this.remove_image = "assets/images/remove.png";
    this.faultList = this.dialogRef.componentInstance.data.projectFaultList;
    this.commentList = this.dialogRef.componentInstance.data.comments;
    this.enduranceList = this.dialogRef.componentInstance.data.endurance;
    this.questionCategoryList =
    this.dialogRef.componentInstance.data.answersByCategory;
    this.userSub = this.authService.user.subscribe((user) => {
      if (user) {
        this.loginInUserRole = user.roleId;
        this.permission = user.appPermissions;
      }
    });
    this.testingHoursValidation(
      this.dialogRef.componentInstance.data.startHours,
      this.dialogRef.componentInstance.data.endHours
    );
    this.createForm(this.questionCategoryList);
    this.scoreOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  }
  onDismiss(): void {
    this.dialogRef.close();
  }

  createForm(questionCategories) {
    var categoryArr = [];
    for (var i = 0; i < questionCategories.length; i++) {
      questionCategories[i].answers.sort(function( a, b ) {
        return Number(a.order) - Number(b.order);
      });
      categoryArr.push(this.appendCategory(questionCategories[i]));
    }

    this.formGroup = this.formBuilder.group({
      categories: this.formBuilder.array(categoryArr),
    });

    this.hoursFormGroup = this.formBuilder.group({
      startHours: [
        {value: this.dialogRef.componentInstance.data.startHours, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
      endHours: [
        {value: this.dialogRef.componentInstance.data.endHours, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
      testingHoursCompleted: [
        { value: this.testingHours, disabled: true },
        Validators.required,
      ],
      fuelFilledQuantity: [
        {value: this.dialogRef.componentInstance.data.fuelFilledQuantity, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
      adBlueQuantity: [
        {value: this.dialogRef.componentInstance.data.adBlueQuantity, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
      hmrAtFuelFilled: [
        {value: this.dialogRef.componentInstance.data.hmrAtFuelFilled, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
      hmrAtAdBlueFilled: [
        {value: this.dialogRef.componentInstance.data.hmrAtAdBlueFilled, disabled: this.permission.includes('AP12') ? false : true },
        Validators.required,
      ],
    });
  }

  appendCategory(questionCategory): FormGroup {
    var questionArr = [];
    for (var j = 0; j < questionCategory.answers.length; j++) {
      questionArr.push(this.appendQuestion(questionCategory.answers[j]));
    }
    return this.formBuilder.group({
      title: [questionCategory.questionCategoryName],
      questions: this.formBuilder.array(questionArr),
    });
  }

  appendQuestion(question): FormGroup {
    return this.formBuilder.group({
      questionName: [question.questionName],
      answer: [{value: question.answer, disabled: this.permission.includes('AP13') ? false : true}],
      levelDistance:[question.levelDistance],
      comment: [{value: question.comment, disabled: this.permission.includes('AP13') ? false : true },[xmlInjectionValidator]],
      driverSheetAnsweredId: [question.driverSheetAnsweredId],
      questionId: [question.questionId],
      score: [{value: question.score, disabled: this.permission.includes('AP13') ? false : true }],
      questionType: [question.questionType],
      mediaUrl: [question.mediaUrl],
    });
  }
  formValid(){
   return false
  }
  showFaultDetails(fault: any) {
    let title = "Fault - ".concat(fault.failureType);
    this.faultDetailsService
      .confirm(
        title,
        fault,
        "MARK AS RESOLVED"
      ).subscribe(res => {
        if (res === "") {
          // this.onSubmitUser();
        }
      })
  }

  isDisabled(value: any,formControlName:any) {
    if(value.reviewed === 'Yes') {
     this.hoursFormGroup.get(formControlName).disable()
    } else {
      this.hoursFormGroup.get(formControlName).enable()
    }
   }

   isDisabledQuestion(value,categoryIndex,questioIndex){
    if(value.reviewed === 'Yes') {
      this.formGroup.get("categories").get([categoryIndex]).get("questions").get([questioIndex]).get('answer').disable() 
      } else {
        this.formGroup.get("categories").get([categoryIndex]).get("questions").get([questioIndex]).get('answer').enable()
      }
   }
   isDisabledComment(value,categoryIndex,questioIndex){
    if(value.reviewed === 'Yes') {
      this.formGroup.get("categories").get([categoryIndex]).get("questions").get([questioIndex]).get("comment").disable()
    } else {
      this.formGroup.get("categories").get([categoryIndex]).get("questions").get([questioIndex]).get("comment").enable()
    }
   }



  getErrorComment(categoryIndex,questioIndex) {
     return this.formGroup.get("categories").get([categoryIndex]).get("questions").get([questioIndex]).get("comment").hasError("jsInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.formGroup.get("categories").get([categoryIndex]).get("questions").get([questioIndex]).get("comment").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }


  viewFault(fault: any) {
    const dialogRef = this.dialog.open(ViewFaultDetailsComponent, {
      data: fault,
    });
  }

  editFault(fault: any) {
    const dialogRef = this.dialog.open(EditFaultDetailsComponent, {
      data: fault,
    });
  }

  deleteFault(faultId: string) {
    this.confirmService
      .confirm(
        "Delete Fault",
        "Are you Sure you want to delete this Fault?",
        "Note that by deleting this Fault will be disabled accross the application, and the data, files and information associated with it will become unavailable",
        "DELETE NOW"
      )
      .pipe(
        switchMap((res) => {
          if (res === true) {
            return this.dashboardService.deleteFault(faultId);
          }
        })
      )
      .subscribe(
        (response) => {
          this.success();
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
          this.messageService.openDialog("Error", "Delete did not happen.");
        }
      );
  }

  viewComments(comment: any) {
    const dialogRef = this.dialog.open(ViewCommentsComponent, {
      data: comment,
    });
  }

  editComments(comment: any) {
    const dialogRef = this.dialog.open(EditCommentsComponent, {
      data: comment,
    });
  }

  deleteComments(driverSheetSubmittedCommentsId: string) {
    this.confirmService
      .confirm(
        "Delete Comment",
        "Are you Sure you want to delete this Comment?",
        "Note that by deleting this Comment will be disabled accross the application, and the data, files and information associated with it will become unavailable",
        "DELETE NOW"
      )
      .pipe(
        switchMap((res) => {
          if (res === true) {
            return this.dashboardService.deleteComment(
              driverSheetSubmittedCommentsId
            );
          }
        })
      )
      .subscribe(
        (response) => {
          this.success();
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
          this.messageService.openDialog("Error", "Delete did not happen.");
        }
      );
  }

  private success() {
    this.messageService.openDialog(
      "Success",
      "Database updated successfully! Please refresh the page"
    );
  }

  

  updateReviewDriverSheet(hoursData:any){
    if(this.submittedDate !== '-'){
      let newData:UpdateDriverSheetReviewed = {
        driverSheetId: this.dialogRef.componentInstance.data.driverSheetId,
        driverSheetSubmittedId: this.dialogRef.componentInstance.data.driverSheetSubmittedId,
        adBlueQuantity: hoursData.adBlueQuantity,
        fuelFilledQuantity: hoursData.fuelFilledQuantity,
        hmrAtAdBlueFilled: hoursData.hmrAtAdBlueFilled,
        hmrAtFuelFilled: hoursData.hmrAtFuelFilled,
        // updatedOn: new Date()
      };

      this.dashboardService.updateReviewDriverSheetApi(newData).subscribe(
        (response) => {
          let responseData: InternalResponse = response;
          handleApiResponse(responseData, this.messageService, this.toastr);
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
    }else{
      this.toastr.error("The Driver Sheet Not Submitted!", "ERROR");

    }
  }

  reviewDriverSheet(reviewData: any, hoursData: any) {
    if(this.submittedDate !== '-'){
      let formValid = true
    for(let i=0;i < this.formGroup['controls']['categories']['controls'].length;i++){
      for(let j=0;j < this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'].length;j++){
        if(this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors'] !== null){
          if( this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors']['jsInjection'] || this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors']['xmlInjection']){
            formValid = false
            break;
          }
        }
      }
    }

    if(formValid){
    if (this.testingHours === 0 || this.testingHours < 0) {
      this.messageService.openDialog(
        "ERROR",
        "Updation Failed. Testing Hours Completed Cannot be 0 or less than 0 hours"
      );
    } else if (this.testingHours > 24) {
      this.messageService.openDialog(
        "ERROR",
        "Updation Failed. Testing Hours Completed Cannot be greater than 24 hours"
      );
    } else {
      let answers = [];
      for (var i = 0; i < reviewData.categories.length; i++) {
        for (var j = 0; j < reviewData.categories[i].questions.length; j++) {
          answers.push(reviewData.categories[i].questions[j]);
        }
      }
      let newData: DriverSheetReviewed = {
        driverSheetId: this.dialogRef.componentInstance.data.driverSheetId,
        driverSheetSubmittedId:
          this.dialogRef.componentInstance.data.driverSheetSubmittedId,
        projectMachineId:
          this.dialogRef.componentInstance.data.projectMachineId,
        active: true,
        adBlueQuantity: hoursData.adBlueQuantity,
        climate: this.dialogRef.componentInstance.data.climate,
        deviceId: this.dialogRef.componentInstance.data.deviceId,
        endHours: hoursData.endHours,
        fuelFilledQuantity: hoursData.fuelFilledQuantity,
        language: this.dialogRef.componentInstance.data.language,
        reviewComment: "NA",
        reviewed: true,
        reviewedOn: this.dialogRef.componentInstance.data.reviewedOn,
        shiftId: this.dialogRef.componentInstance.data.shiftId,
        startHours: hoursData.startHours,
        temperature: this.dialogRef.componentInstance.data.temperature,
        testingHoursCompleted: this.testingHours,
        userId: this.dialogRef.componentInstance.data.userId,
        vin: this.dialogRef.componentInstance.data.vin,
        hmrAtAdBlueFilled: hoursData.hmrAtAdBlueFilled,
        hmrAtFuelFilled: hoursData.hmrAtFuelFilled,
        answers: answers.map((answer) => {
          return {
            driverSheetAnsweredId: answer.driverSheetAnsweredId,
            questionId: answer.questionId,
            comment: answer.comment,
            answer: answer.answer,
            mediaUrl: answer.mediaUrl,
            score: answer.score,
          };
        }),
      };

      this.dashboardService.reviewDriverSheet(newData).subscribe(
        (response) => {
          let responseData: InternalResponse = response;
          handleApiResponse(responseData, this.messageService, this.toastr);
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
    }
  }else{
    this.toastr.error("SQL/XML/Script pattern not allowed. Please check.", "ERROR");
  }
}else{
  this.toastr.error("The Driver Sheet not Submitted!", "ERROR");
}
  }

  updateDriverSheet(updateddata: any, hoursData: any) {

    let formValid = true

    for(let i=0;i < this.formGroup['controls']['categories']['controls'].length;i++){
      for(let j=0;j < this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'].length;j++){
        if(this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors'] !== null){
          if( this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors']['jsInjection'] || this.formGroup['controls']['categories']['controls'][i]['controls']['questions']['controls'][j]['controls']['comment']['errors']['xmlInjection']){
            formValid = false
            break;
          }
        }
      }
    }
    if(formValid){
      if (this.testingHours === 0 || this.testingHours < 0) {
        this.messageService.openDialog(
          "ERROR",
          "Updation Failed. Testing Hours Completed Cannot be 0 or less than 0 hours"
        );
      } else if (this.testingHours > 24) {
        this.messageService.openDialog(
          "ERROR",
          "Updation Failed. Testing Hours Completed Cannot be greater than 24 hours"
        );
      } else {
        let answers = [];
        for (var i = 0; i < updateddata.categories.length; i++) {
          for (var j = 0; j < updateddata.categories[i].questions.length; j++) {
            answers.push(updateddata.categories[i].questions[j]);
          }
        }
        let newData: DriverSheetSubmitted = {
          driverSheetId: this.dialogRef.componentInstance.data.driverSheetId,
          driverSheetSubmittedId:
            this.dialogRef.componentInstance.data.driverSheetSubmittedId,
          projectMachineId:
            this.dialogRef.componentInstance.data.projectMachineId,
          active: true,
          adBlueQuantity: hoursData.adBlueQuantity,
          climate: this.dialogRef.componentInstance.data.climate,
          deviceId: this.dialogRef.componentInstance.data.deviceId,
          endHours: hoursData.endHours,
          fuelFilledQuantity: hoursData.fuelFilledQuantity,
          language: this.dialogRef.componentInstance.data.language,
          reviewComment: "NA",
          shiftId: this.dialogRef.componentInstance.data.shiftId,
          startHours: hoursData.startHours,
          temperature: this.dialogRef.componentInstance.data.temperature,
          testingHoursCompleted: this.testingHours,
          userId: this.dialogRef.componentInstance.data.userId,
          vin: this.dialogRef.componentInstance.data.vin,
          hmrAtAdBlueFilled: hoursData.hmrAtAdBlueFilled,
          hmrAtFuelFilled: hoursData.hmrAtFuelFilled,
          answers: answers.map((answer) => {
            return {
              driverSheetAnsweredId: answer.driverSheetAnsweredId,
              questionId: answer.questionId,
              comment: answer.comment,
              answer: answer.answer,
              mediaUrl: answer.mediaUrl,
              score: answer.score,
            };
          }),
        };
  
        this.dashboardService.updateDriverSheetSubmitted(newData).subscribe(
          (response) => {
            let responseData: InternalResponse = response;
            handleApiResponse(responseData, this.messageService, this.toastr);
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.message);
          }
        );
      }
    }else{
      this.toastr.error("SQL/XML/Script pattern not allowed. Please check.", "ERROR");
    }
  
  }

  testingHoursValidation(startHours, endHours): string {
    let testingHoursCompleted = endHours - startHours;
    this.testingHours = Math.round(testingHoursCompleted * 10) / 10;
    if (testingHoursCompleted === 0) {
      return "Testing Hours Completed Cannot be 0";
    } else if (testingHoursCompleted < 25) {
      return "SUCCESS";
    } else {
      return "Testing Hours Completed Cannot be greater than 24";
    }
  }

  onkeyUpDown(){
    return false;
  }
  
}
