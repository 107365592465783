import { OnInit, Component } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import {
  success_image,
  failure_image
} from "src/app/config/const";

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["../shared-style.css"]
})
export class MessagesComponent implements OnInit {
  public title: string;
  public message: string;
  public success_image: string;
  public failure_image: string;
  isSuccess: boolean;

  constructor(public dialogRef: MatDialogRef<MessagesComponent>) { }

  private closeWithTimer() {
    setTimeout(() => {
      this.dialogRef.close();
    }, 2000);
  }

  ngOnInit() {
    this.success_image = success_image;
    this.failure_image = failure_image;
    if (this.title === 'SUCCESS' || this.title === 'success' || this.title === 'Success') {
      this.isSuccess = true;
    } else {
      this.isSuccess = false;
    }
  }

  onDismiss() {
    this.dialogRef.close();
  }
}
