import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { catchError, map } from "rxjs/operators";

import {
  GetPasswordRequest,
  createUrl,
  getHeader,
  handleError,
  modifyResponse,
  Project,
  Machine,
  ProjectUser,
  PojectEnduranceCycle,
  DriverSheet,
  DSTemplateRequest,
  Fault,
  Comments,
  DriverSheetReviewed,
  DriverSheetDetails,
  DriverSheetSubmitted,
  UpdateDriverSheetReviewed,
} from "src/app/services";
import {
  GET_ALL_DASHBOARD_COUNTS,
  GET_QUICKSIGHT_DASHBOARD_URL,
  GET_PROJECT_DETAILS,
  GET_PROJECT_LIST,
  GET_PROJECT_MACHINE_LIST,
  PROJECT_MACHINE_API,
  PROJECT_USER_API,
  PROJECT_ENDURANCE_API,
  DRIVER_SHEETS_API,
  GET_PROJECT_FAULT_LIST,
  GET_PROJECT_COMPONENTS_LIST,
  UPDATE_COMPONENTS,
  DS_TEMPLATE_LIST,
  DS_TEMPLATES_API,
  GET_ROLE_USER_LIST,
  GET_OPERATORS_NAMES,
  GET_PROJECT_COMMENTS,
  GET_PROJECT_DETAILS_LIST,
  PROJECT_ACTIVATE,
  PROJECT_MACHINE_ACTIVATE,
  QUESTION_BANK,
  GET_FAULT,
  GET_COMMENT,
  DS_REVIEWED_API,
  GET_MACHINE_DETAILS_LIST,
  DS_SUBMITTED_API,
  PROJECT_REPORT_API,
  PROJECT_MACHINE_REPORT_API,
  GET_PASSWORD,
  GET_QUESTION_DETAILS,
  DELETE_COMPONENT,
  DELETE_PROJECT_WIKI,
  ADD_PROJECT_WIKI_LIST,
  UPDATE_PROJECT_WIKI,
  GET_PROJECT_WIKI_LIST,
  CLONE_PROJECT_WIKI

} from "src/app/config/backend.api.urls";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  dialogData: any;

  title = new BehaviorSubject<string>("");

  constructor(private http: HttpClient) {}

  public setTitle(newTitle: string) {
    this.title.next(newTitle);
  }

  getDialogData() {
    return this.dialogData;
  }

  /*************************************Dashboard Data********************************/

  /**
   * GETS THE COUNT OF PROJECT, OPERATORS AND MACHINES
   * @returns
   */
  getDashboardCount() {
    return this.http
      .get(createUrl(GET_ALL_DASHBOARD_COUNTS), { headers: getHeader() })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_ALL_DASHBOARD_COUNTS);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Get Amazon quicksight embedded URL from backend
   * @returns Quicksight URL
   */
  getQuickSightUrl() {
    return this.http
      .get(createUrl(GET_QUICKSIGHT_DASHBOARD_URL), {
        headers: getHeader(),
        responseType: "text",
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(
            unauthorizedResponse,
            GET_QUICKSIGHT_DASHBOARD_URL
          );
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * GETS THE LIST OF PROJECT, OPERATORS AND MACHINES
   * @param sort COLUMN NAME
   * @param order ASC/DESC
   * @param pageNumber 1-TOTAL LENGTH / PAGE_SIZE
   * @param pageSize 5/10/25/100
   * @returns
   */
  getDashboardDataList(
    url: string,
    // sort: string,
    // order: string,
    pageNumber: number,
    pageSize: number
  ) {
    const params = new HttpParams()
      // .set("sort", sort)
      // .set("order", order)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http
      .get(createUrl(url), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, url);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * GETS ALL DASHBOARD DATA
   * ACTIVITY LIST
   * VIN LIST
   * @param URL Get the url of the API
   * @returns Return the data a/c to th url
   */
  getDashboardData(URL: string) {
    return this.http.get(createUrl(URL), { headers: getHeader() }).pipe(
      catchError((unauthorizedResponse: any) => {
        return handleError(unauthorizedResponse, URL);
      }),
      map((response) => {
        return modifyResponse(response);
      })
    );
  }

  /*************************************Project********************************/

  getDSTemplateList() {
    return this.http
      .get(createUrl(DS_TEMPLATE_LIST), {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, DS_TEMPLATE_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * GET THE DATA OF SELECTED TEMPLATE
   * @param driverSheetTemplateId DRIVERSHEET ID TO FETCH THE TEMPLATE DATA
   * @returns
   */
  getDSTemplateData(driverSheetTemplateId: string) {
    const params = new HttpParams().set(
      "driverSheetTemplateId",
      driverSheetTemplateId
    );
    return this.http
      .get(createUrl(DS_TEMPLATES_API), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, DS_TEMPLATES_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * ADD NEW DRIVER SHEET TEMPLATE
   * @param data
   * @returns
   */
  addNewDSTemplate(data: DSTemplateRequest) {
    return this.http
      .post(createUrl(DS_TEMPLATES_API), data, {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, DS_TEMPLATES_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

    /**
   * GET OPERATOR PASSWORD
   * @param data
   * @returns
   */
    getPassword(data: GetPasswordRequest) {
      return this.http
        .post(createUrl(GET_PASSWORD), data, {
          headers: getHeader(),
        })
        .pipe(
          catchError((unauthorizedResponse: any) => {
            return handleError(unauthorizedResponse, GET_PASSWORD);
          }),
          map((response) => {
            return modifyResponse(response);
          })
        );
    }

  /**
   * UPDATE NEW DRIVER SHEET TEMPLATE
   * @param data
   * @returns
   */
  updateDSTemplate(data: DSTemplateRequest) {
    return this.http
      .put(createUrl(DS_TEMPLATES_API), data, {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, DS_TEMPLATES_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /*************************************Project********************************/
  /**
   * GET PROJECT'S DETAILS USING ID
   * @param projectId
   * @returns
   */
  getProjectDetails(projectId: string) {
    const params = new HttpParams().set("projectId", projectId);
    return this.http
      .get(createUrl(GET_PROJECT_DETAILS), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_DETAILS);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * ADD A NEW PROJECT
   * @param newProjectData
   * @returns
   */
  addNewProject(newProjectData: Project) {
    return this.http
      .post(createUrl(GET_PROJECT_LIST), newProjectData, {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * CLONE PROJECT WIKI
   * @param projectId
   * @param newProjectId
   * @returns
   */
   cloneProjectWiki(projectId: string,newProjectId:string) {
    const params = new HttpParams().set('projectId',projectId).set("newProjectId",newProjectId)
    return this.http
          .post(createUrl(CLONE_PROJECT_WIKI), {projectId,newProjectId}, {
            headers: getHeader(),
            params: params,
          })
          .pipe(
            catchError((unauthorizedResponse: any) => {
              return handleError(unauthorizedResponse, CLONE_PROJECT_WIKI);
            }),
            map((response) => {
              return modifyResponse(response);
            })
          );
      }
  /**
   * EDIT THE EXISTING PROJECT USING PROJECT'ID
   * @param updatedProjectData
   * @returns
   */
  editProject(updatedProjectData: Project) {
    const params = new HttpParams()
      .set("projectId", updatedProjectData.projectId)
      .set("description", updatedProjectData.description)
      .set("imageUrl", updatedProjectData.imageUrl)
      .set("projectName", updatedProjectData.projectName)
      .set("active", updatedProjectData.active.toString());

    return this.http
      .put(createUrl(GET_PROJECT_LIST), updatedProjectData, {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * DELETE PROJECT USING project'ID
   * @param projectId
   * @returns
   */
  deleteProject(projectId: string) {
    const params = new HttpParams().set("projectId", projectId);
    return this.http
      .delete(createUrl(GET_PROJECT_LIST), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  activateProject(projectId: string) {
    const params = new HttpParams().set("projectId", projectId);
    return this.http
      .put(createUrl(PROJECT_ACTIVATE), projectId, {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PROJECT_ACTIVATE);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Gets project list
   * @returns
   */
  getProjectList() {
    return this.http
      .get(createUrl(GET_PROJECT_LIST), {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  getProjectDetailsList(active: string) {
    const params = new HttpParams().set("active", active);
    return this.http
      .get(createUrl(GET_PROJECT_DETAILS_LIST), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_DETAILS_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  //---------------------------------Project Wiki ------------------------------
  getProjectWikiList(projectId: string) {
    const params = new HttpParams().set("projectId", projectId);
    return this.http
      .get(createUrl(GET_PROJECT_WIKI_LIST), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_WIKI_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }


  deleteProjectWikiList(fileId: string,projectId:string) {
    const params = new HttpParams().set("wikiId", fileId).set("projectId",projectId);
    return this.http
      .delete(createUrl(DELETE_PROJECT_WIKI), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, DELETE_PROJECT_WIKI);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  updateProjectWikiList(projectWiki:any) {
  return this.http
      .put(createUrl(UPDATE_PROJECT_WIKI),projectWiki, {
        headers: getHeader()
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, UPDATE_PROJECT_WIKI);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  addProjectWkiList(fileData:any) {
    return this.http
      .post(createUrl(ADD_PROJECT_WIKI_LIST),fileData, {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, ADD_PROJECT_WIKI_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }



  // -------------------------------- Project Machine --------------------------
  /**
   * Get Projects Machine list
   * @param projectId
   * @returns
   */
  getProjectMachineList(projectId: string) {
    const params = new HttpParams().set("projectId", projectId);
    return this.http
      .get(createUrl(GET_PROJECT_MACHINE_LIST), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_MACHINE_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  editProjectMachine(updatedMachinedata: Machine) {
    this.dialogData = updatedMachinedata;
    const params = new HttpParams()
      .set("vin", updatedMachinedata.vin)
      .set("modelId", updatedMachinedata.modelId)
      .set("active", updatedMachinedata.active)
      .set("platformId", updatedMachinedata.platformId)
      .set("plantId", updatedMachinedata.plantId)
      .set("targetHours", updatedMachinedata.targetHours)
      .set("projectMachineId", updatedMachinedata.projectMachineId);
    return this.http
      .put(createUrl(PROJECT_MACHINE_API), updatedMachinedata, {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PROJECT_MACHINE_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Deletes project machine
   * @param projectMachineId
   * @returns
   */
  deleteProjectMachine(projectMachineId: string) {
    const params = new HttpParams().set("projectMachineId", projectMachineId);
    return this.http
      .delete(createUrl(PROJECT_MACHINE_API), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PROJECT_MACHINE_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  activateProjectMachine(projectMachineId: string) {
    this.dialogData = projectMachineId;
    const params = new HttpParams().set("projectMachineId", projectMachineId);
    return this.http
      .put(createUrl(PROJECT_MACHINE_ACTIVATE), projectMachineId, {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PROJECT_MACHINE_ACTIVATE);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  // -------------------------------- Project Users --------------------------

  editProjectUser(updatedUserdata: ProjectUser) {
    this.dialogData = updatedUserdata;
    const params = new HttpParams()
      .set("projectUserId", updatedUserdata.projectUserId)
      .set("responsibility", updatedUserdata.responsibility)
      .set("role", updatedUserdata.role)
      .set("mobileNumber", updatedUserdata.mobileNumber)
      .set("plantId", updatedUserdata.plantId)
      .set("projectId", updatedUserdata.projectId)
      .set("active", updatedUserdata.active.toString());
    return this.http
      .put(createUrl(PROJECT_USER_API), updatedUserdata, {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PROJECT_USER_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Deletes project machine
   * @param projectMachineId
   * @returns
   */
  deleteProjectUser(projectMachineId: string) {
    const params = new HttpParams().set("projectUserId", projectMachineId);
    return this.http
      .delete(createUrl(PROJECT_USER_API), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PROJECT_USER_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  // -------------------------------- Project Endurance Cycle --------------------------

  editProjectEndurance(updatedMachinedata: PojectEnduranceCycle) {
    this.dialogData = updatedMachinedata;
    const params = new HttpParams()
      .set(
        "projectEnduranceCycleId",
        updatedMachinedata.projectEnduranceCycleId
      )
      .set("enduranceCycleId", updatedMachinedata.enduranceCycleId)
      .set("instruction", updatedMachinedata.instruction)
      .set("efforts", updatedMachinedata.efforts)
      .set("hours", updatedMachinedata.unit);
    return this.http
      .put(createUrl(PROJECT_ENDURANCE_API), updatedMachinedata, {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PROJECT_ENDURANCE_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  deleteProjectEndurance(projectEnduranceCycleId: string) {
    const params = new HttpParams().set(
      "projectEnduranceCycleId",
      projectEnduranceCycleId
    );
    return this.http
      .delete(createUrl(PROJECT_ENDURANCE_API), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PROJECT_ENDURANCE_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  // -------------------------------- Project Driver Sheet --------------------------

  /**
   * Adds new driver sheet
   * @param newDriverSheetData
   * @returns
   */
  addNewDriverSheet(newDriverSheetData: DriverSheet) {
    return this.http
      .post(createUrl(DRIVER_SHEETS_API), newDriverSheetData, {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, DRIVER_SHEETS_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Update driver sheet Submitted
   * @param driverSheetData
   * @returns
   */
  updateDriverSheetSubmitted(driverSheetData: DriverSheetSubmitted) {
    return this.http
      .put(createUrl(DS_SUBMITTED_API), driverSheetData, {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, DS_SUBMITTED_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Review driver sheet
   * @param reviewDriverSheetData
   * @returns
   */
  reviewDriverSheet(reviewDriverSheetData: DriverSheetReviewed) {
    return this.http
      .post(createUrl(DS_REVIEWED_API), reviewDriverSheetData, {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, DS_REVIEWED_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }


  /**
   * update Review driver sheet
   * @param reviewDriverSheetData
   * @returns
   */
  updateReviewDriverSheetApi(reviewDriverSheetData: UpdateDriverSheetReviewed) {
    return this.http
      .put(createUrl(DS_REVIEWED_API), reviewDriverSheetData, {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, DS_REVIEWED_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }
  // -------------------------------- Project Fault --------------------------
  /**
   * Gets Project fault list
   * @param projectId
   * @returns
   */
  getProjectFaultList(
    projectId: string,
    createdBy: any,
    status: string,
    order: string
  ) {
    const params = new HttpParams()
      .set("projectId", projectId)
      .set("createdBy", createdBy)
      .set("status", status)
      .set("order", order);
    return this.http
      .get(createUrl(GET_PROJECT_FAULT_LIST), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_FAULT_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

   // -------------------------------- Project Components--------------------------
  /**
   * Gets Project component list
   * @param projectId
   * @returns
   */
  getProjectComponentList(
    projectId: string,
    createdBy: any,
    status: string,
    order: string
  ) {
    const params = new HttpParams()
      .set("projectId", projectId)
      .set("createdBy", createdBy)
      .set("status", status)
      .set("sortBy", order);
    return this.http
      .get(createUrl(GET_PROJECT_COMPONENTS_LIST), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_COMPONENTS_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

   // -------------------------------- Project Components--------------------------
  /**
   * update Project component list
   * @param componentId
   * @returns
   */
  // componentId=4&statusDescription=dfdfdfdf&active=false&failedDate=2023-08-12&failedHmr=33

  updateProjectComponentList(
    componentId: string,
    statusDescription: any,
    isFailed: any,
    failedDate: string,
    failedHmr:any,
  ) {
    const params = new HttpParams()
      .set("componentId", componentId)
      .set("statusDescription", statusDescription)
      .set("isFailed", isFailed)
      .set("failedDate", failedDate)
      .set("failedHmr", failedHmr);
      const bodyParams = {
        componentId,
        statusDescription,
        isFailed,
        failedDate,
        failedHmr
      }

    return this.http
      .put(createUrl(UPDATE_COMPONENTS),bodyParams, {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, UPDATE_COMPONENTS);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  // -------------------------------- Project Comments --------------------------

  /**
   * Fetch comment list
   * @param projectId
   * @param ownerId
   * @returns
   */
  fetchCommentList(projectId: string, userId: string, order: string) {
    const params = new HttpParams()
      .set("projectId", projectId)
      .set("userId", userId)
      .set("order", order);
    return this.http
      .get(createUrl(GET_PROJECT_COMMENTS), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_PROJECT_COMMENTS);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  getUserAccodingToRole(roleId: string) {
    const params = new HttpParams().set("roleId", roleId);
    return this.http
      .get(createUrl(GET_ROLE_USER_LIST), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_ROLE_USER_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  getOperatorList() {
    return this.http
      .get(createUrl(GET_OPERATORS_NAMES), {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_OPERATORS_NAMES);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  getQuestionBankList() {
    return this.http
      .get(createUrl(QUESTION_BANK), {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, QUESTION_BANK);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  addNewFault(fault: Fault) {
    return this.http
      .put(createUrl(GET_FAULT), fault, {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_FAULT);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * DELETE FAULT using faultId
   * @param faultId
   * @returns
   */
  deleteFault(faultId: string) {
    const params = new HttpParams().set("faultId", faultId);
    return this.http
      .delete(createUrl(GET_FAULT), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_FAULT);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * DELETE COMPONENT using componentId
   * @param componentId
   * @returns
   */
  deleteComponent(componentId: string) {
    const params = new HttpParams().set("componentId", componentId);
    return this.http
      .delete(createUrl(DELETE_COMPONENT), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, DELETE_COMPONENT);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  updateComment(comment: Comments) {
    const param = new HttpParams()
      .set(
        "driverSheetSubmittedCommentsId",
        comment.driverSheetSubmittedCommentsId
      )
      .set("driverSheetSubmittedId", comment.driverSheetSubmittedId)
      .set("title", comment.title)
      .set("description", comment.description)
      .set("userId", comment.userId)
      .set("vin", comment.vin);
    return this.http
      .put(createUrl(GET_COMMENT), comment, {
        headers: getHeader(),
        params: param,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_COMMENT);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * DELETE COMMENTS using driverSheetSubmittedCommentsId
   * @param driverSheetSubmittedCommentsId
   * @returns
   */
  deleteComment(driverSheetSubmittedCommentsId: string) {
    const params = new HttpParams().set(
      "driverSheetSubmittedCommentsId",
      driverSheetSubmittedCommentsId
    );
    return this.http
      .delete(createUrl(GET_COMMENT), {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_COMMENT);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * Get Machine Details list
   * @returns
   */
  getMachineDetailsList() {
    return this.http
      .get(createUrl(GET_MACHINE_DETAILS_LIST), {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_MACHINE_DETAILS_LIST);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * GENERATING NEW PROJECT REPORT
   * @param projectId
   * @returns
   */
  generateProjectReport(projectId: string) {
    const params = new HttpParams().set("projectId", projectId);
    return this.http
      .post(createUrl(PROJECT_REPORT_API), projectId, {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PROJECT_REPORT_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }

  /**
   * GENERATING NEW PROJECT MACHINE REPORT
   * @param projectId, @param projectMachineId
   * @returns
   */
  generateProjectMachineReport(projectId: string, projectMachineId: string) {
    const params = new HttpParams()
      .set("projectId", projectId)
      .set("projectMachineId", projectMachineId);
    return this.http
      .post(createUrl(PROJECT_MACHINE_REPORT_API), projectId, {
        headers: getHeader(),
        params: params,
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, PROJECT_MACHINE_REPORT_API);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }


  getQuestionsDetails(questionData: any) {
    return this.http
      .post(createUrl(GET_QUESTION_DETAILS), questionData, {
        headers: getHeader(),
      })
      .pipe(
        catchError((unauthorizedResponse: any) => {
          return handleError(unauthorizedResponse, GET_QUESTION_DETAILS);
        }),
        map((response) => {
          return modifyResponse(response);
        })
      );
  }
}
