import { Observable } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material";
import { Injectable } from "@angular/core";
import { ExpandImageComponent } from './expand-image.component';

@Injectable({
  providedIn: 'root'
})
export class ExpandImageService {
  private dialogRef: MatDialogRef<ExpandImageComponent>;

  constructor(private dialog: MatDialog) { }

  public openImage(imageUrl: string): Observable<any> {
    this.dialogRef = this.dialog.open(ExpandImageComponent,{});
    this.dialogRef.componentInstance.imageUrl = imageUrl;

    return this.dialogRef.afterClosed();
  }
}
