import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DashboardService } from "src/app/modules/dashboard/dashboard.service";
import {
  InternalResponse,
  INTERNAL_RESPONSE_STATUS,
  Plants,
} from "src/app/services";
import { handleApiResponse } from "src/app/config/helper.function";
import { HttpErrorResponse } from "@angular/common/http";
import { PlantService } from "src/app/modules/plant";
import { emailregex } from "src/app/config/const";
import { MessagesService } from "src/app/shared/messages-service/messages.service";
import { sqlInjectionValidator,xmlInjectionValidator } from "src/app/config/custom-validator.directive";

@Component({
  selector: "app-edit-plant",
  templateUrl: "./edit-plant.component.html",
  styleUrls: ["../../../style.css"],
})
export class EditPlantComponent implements OnInit {
  isLoading: boolean = false;
  title: string;
  plantForm: FormGroup;
  buttonName: string;
  public plantList: any;
  public plantNameList: any;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditPlantComponent>,
    public plantService: PlantService,
    public messageService: MessagesService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.getPlantList();
    this.title = this.data.title;
    this.buttonName = this.data.buttonName;
    this.createForm();
    if (this.data.action === "EDIT") {
      this.patchFormValue();
    }
  }

  getPlantList() {
    this.plantService.getPlantList().subscribe(
      (response) => {
        let responseData: InternalResponse = response;
        if (responseData.status === INTERNAL_RESPONSE_STATUS.SUCCESS) {
          this.plantList = response.success_data.plants;
          this.plantList = this.plantList.filter((pl) => pl.active === true);
          this.plantNameList = this.plantList.map((plant: any) => plant.name);
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.message);
      }
    );
  }

  createForm() {
    this.plantForm = this.formBuilder.group({
      plantId: [null],
      plantName: [null, [Validators.required, Validators.minLength(3),xmlInjectionValidator]],
      city: [null, [Validators.required, Validators.minLength(2),xmlInjectionValidator]],
      address: [null, [Validators.required,xmlInjectionValidator]],
      country: [null, [Validators.required,xmlInjectionValidator]],
      // plantPOCEmail: [null, [Validators.required, Validators.pattern(emailregex)]],
      active: [null, [Validators.requiredTrue]],
    });
  }

  patchFormValue() {
    this.plantForm = this.formBuilder.group({
      plantId: [this.data.plantData.plantId],
      plantName: [this.data.plantData.plantName,[Validators.required, Validators.minLength(3),xmlInjectionValidator]],
      city: [this.data.plantData.city,[Validators.required, Validators.minLength(2),xmlInjectionValidator]],
      address: [this.data.plantData.address,[Validators.required,xmlInjectionValidator]],
      country: [this.data.plantData.country,[Validators.required,xmlInjectionValidator]],
      // plantPOCEmail: [this.data.plantData.plantPOCEmail, [Validators.required, Validators.pattern(emailregex)]],
      active: [this.data.plantData.active],
    });
    this.isLoading = false;
  }

  /**
   * Edits Plants
   * @param updatedplantData
   */
  editPlant(updatedplantData: Plants, formDirective: FormGroupDirective) {
    if (this.plantNameList.includes(updatedplantData.plantName)) {
      this.toastr.error("Plant with given name already exists", "ERROR");
      return;
    }
    this.isLoading = true;
    if (this.data.action === "EDIT") {
      updatedplantData.plantId = this.data.plantData.plantId;
      this.plantService.editPlant(updatedplantData).subscribe(
        (response) => {
          let responseData: InternalResponse = response;
          handleApiResponse(responseData, this.messageService, this.toastr);
          this.dialogRef.close(true);
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
    } else {
      this.plantService.addNewPlant(updatedplantData).subscribe(
        (response) => {
          let responseData: InternalResponse = response;
          handleApiResponse(responseData, this.messageService, this.toastr);
          this.isLoading = false;
          this.dialogRef.close(true);
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.message);
        }
      );
    }
    this.plantForm.reset();
    formDirective.resetForm();
  }
  xmlInjection

  getErrorPlantName() {
    return this.plantForm.get("plantName").hasError("required")
      ? "Plant Name is required."
      : this.plantForm.get("plantName").hasError("minlength")
      ? "Minimum 3 character required."
      :this.plantForm.get("plantName").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.plantForm.get("plantName").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }

  getErrorCity() {
    return this.plantForm.get("city").hasError("required")
      ? "City is required."
      : this.plantForm.get("city").hasError("minlength")
      ? "Minimum 2 character required."
      :this.plantForm.get("city").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.plantForm.get("city").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }

  getErrorAddress() {
    return this.plantForm.get("address").hasError("required")
      ? "Address is required."
      :this.plantForm.get("address").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.plantForm.get("address").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }

  getErrorCountry() {
    return this.plantForm.get("country").hasError("required")
      ? "Description is required."
      :this.plantForm.get("country").hasError("sqlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      :this.plantForm.get("country").hasError("xmlInjection")
      ? "SQL/XML/Script pattern not allowed. Please check."
      : "";
  }

  // getErrorPlantPOCEmail() {
  //   return this.plantForm.get("plantPOCEmail").hasError("required")
  //     ? "Email is required."
  //     : this.plantForm.get("plantPOCEmail").hasError("pattern")
  //       ? "Not a valid email address (domain must be jcb.com)."
  //       : "";
  // }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
